<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="商品" prop="goods_name" >
        <el-input v-model="form.goods.name" placeholder="请选择商品" disabled></el-input>
      </el-form-item>
      <el-form-item label="库存" prop="count" required>
        <el-input v-model="form.count" placeholder="请输入库存"></el-input>
      </el-form-item>
      <!-- <el-form-item label="进价" prop="purchase_price" required>
        <el-input v-model="form.purchase_price" placeholder="请输入进价"></el-input>
      </el-form-item> -->
      <el-form-item label="代理价" prop="agent_price" required>
        <el-input v-model="form.agent_price" placeholder="请输入代理价"></el-input>
      </el-form-item>
      <el-form-item label="零售价" prop="price" required>
        <el-input v-model="form.price" placeholder="零售价"></el-input>
      </el-form-item>
      <el-form-item label="仓库" prop="warehouse_id">
        <el-select v-model="form.warehouse_id" clearable filterable placeholder="请选择仓库" style="width:100%">
          <el-option
            v-for="(value,key) in warehouseOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="包装类型" prop="chest_ids">
        <el-select v-model="form.chest_ids" multiple clearable filterable placeholder="请选择包装" style="width:100%">
          <el-option
            v-for="(value,key) in chestOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否上架" prop="status">
        <el-radio-group v-model="form.status" size="small">
          <el-radio :label="0" border>未上架</el-radio>
          <el-radio :label="1" border>已上架</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="代理商" prop="agent_id">
        <el-select v-model="form.agent_id" clearable filterable placeholder="请选择代理商" style="width:100%">
          <el-option
            v-for="(value,key) in agentOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="购买单位" prop="unit" required>
        <el-select v-model="form.unit" clearable filterable placeholder="请选择购买单位" style="width:100%">
          <el-option
            v-for="(value,key) in unitOptions"
            :key="key"
            :label="value.label"
            :value="value.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="仓库类型" prop="type" required>
        <el-select v-model="form.type" clearable filterable placeholder="请选择仓库类型" style="width:100%">
          <el-option
            v-for="(value,key) in typeOptions"
            :key="key"
            :label="value.label"
            :value="value.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="editData()" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  import {getStockById,editStock,getWarehousesListSelect,getChestListSelect} from "@/api/stocks.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      id: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        toolsConfig: ["districts"],
        agentOptions: [],
        warehouseOptions: [],
        chestOptions:[],
        unitOptions: [{
          value: 0,
          label: '1瓶装'
        }, {
          value: 1,
          label: '2瓶装'
        }, {
          value: 2,
          label: '3瓶装'
        }, {
          value: 3,
          label: '6瓶装'
        }],
        typeOptions: [{
          value: 0,
          label: '本地仓库'
        }, {
          value: 1,
          label: '云仓'
        }],
        form: {
          goods_id:'',
          status: "",
          warehouse_id: "",
          agent_id: 0,
          unit:'',
          type:'',
          purchase_price:''
        },
        rules: {
          "count": [
            {required: true, message: "请输入库存", trigger: "blur"},
          ],
          "warehouse_id": [
            {required: true, message: "请选择仓库", trigger: "change"},
          ],
          "unit": [
            {required: true, message: "请选择单位", trigger: "change"},
          ],
          "type": [
            {required: true, message: "请选择类型", trigger: "change"},
          ],
          "purchase_price": [
            {required: true, message: "请输入进价", trigger: "blur"},
          ],
        },
        
        dialogVisible:false,
        goodsList:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        console.log(this.id)
        getStockById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            // console.log(res.data)
            console.log(this.form)
          }
        })
        getChestListSelect().then(res=>{
          if(res.code === 200){
            this.chestOptions = res.data
            console.log(this.chestOptions)
          }
        })
        getWarehousesListSelect().then(res=>{
          if(res.code === 200){
            this.warehouseOptions = res.data
            console.log(this.warehouseOptions)
          }
        })
        // getTypeSelect().then(res=>{
        //   if(res.code === 200){
        //     this.typeOptions = res.data
        //     // console.log(this.typeOptions)
        //   }
        // })
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']+data['title']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
            this.form.lat = data["location"]["lat"];
            this.form.lng = data["location"]["lng"];
            this.form.address = data['address']+data['title']
          }
        })
      },

      serviceCascaderChange(e){
        let ids = []
        let names = []
        let object = this.$refs["service_cascader"].getCheckedNodes()
        Object.keys(object).map((key) => [object[key], key]).forEach(([value,key]) => {
          //循环操作
          if(!value.hasChildren){
            ids.push(value.value)
            names.push(value.label)
          }
        })
        this.service_data.service_ids = ids
        this.service_data.service_ids_name = names
      },

      close() {
        _this.$emit("close");
      },

      editData() {
        editStock(_this.id, _this.form).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            setTimeout(() => {
              _this.$emit("success");
            }, 1000);
          }
        });
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.license = e[0];
      },
      handleLicenseRemove(e) {
        this.form.license = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        this.form.storeImg = e;
      },
      handlePicRemove(e) {
        this.form.storeImg = "";
      },

      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
