<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">物流价格信息</el-divider>
      
      <el-form-item label="仓库" prop="warehouse_id" required>
        <el-select v-model="form.warehouse_id" clearable filterable placeholder="请选择仓库" style="width:100%">
          <el-option
            v-for="(value,key) in warehouseOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省份" prop="province_id">
        <div style="display: flex;justify-content:space-between;" v-for="(v,k) in form.adds" :key="k">
          <districts-cascader v-if="v.show" style="margin-bottom:20px;width:90%;" :level="2" :showAll="true" :province_id="service_data.province_code" :city_id="service_data.city_code" :area_id="service_data.area_code" @choose="handleAreaDistrictsChoose(k,$event)"></districts-cascader>
          <i class="el-icon-delete"  @click="deleteAdd(k)" v-if="k>0 && v.show" style="font-size:16px;margin-top:13px;"></i>
        </div>
        <el-button type="primary" plain @click="addArea">添加地区 +</el-button>
      </el-form-item>
      <!-- <el-form-item label="城市" prop="city_id" required>
        <el-select v-model="form.city_id" placeholder="请选择城市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区" prop="area_id" required>
        <el-select v-model="form.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="物流公司" prop="logistic_id" required>
        <el-select v-model="form.logistic_id" clearable filterable placeholder="请选择物流公司" style="width:100%">
          <el-option
            v-for="(value,key) in companyOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低消费" prop="free_amount" require>
        <el-input v-model="form.free_amount" placeholder="请输入最低消费"></el-input>
      </el-form-item>
      <el-form-item label="首重重量" prop="first_weight" require>
        <el-input v-model="form.first_weight" placeholder="请输入首重重量">
          <template slot="append">kg</template>
        </el-input>
          
      </el-form-item>
      <el-form-item label="首重价格" prop="first_weight_price" require>
        <el-input v-model="form.first_weight_price" placeholder="请输入首重价格"></el-input>
      </el-form-item>
      <el-form-item label="续重单位" prop="continuous_weight" require>
        <el-input v-model="form.continuous_weight" placeholder="请输入续重单位">
          <template slot="append">kg</template>
        </el-input>
      </el-form-item>
      <el-form-item label="续重价格" prop="continuous_price" require>
        <el-input v-model="form.continuous_price" placeholder="请输入续重价格"></el-input>
      </el-form-item>
      
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {addPrice,addPriceMulti,getCompaniesListSelect} from "@/api/logistics.js";
  import {getWarehousesListSelect} from "@/api/stocks.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";
  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      row: Object,
      editId: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        toolsConfig: ["districts"],
        provinceList:[],
        cityList:[],
        areaList:[],
        companyOptions:[],
        warehouseOptions:[],
        province_id: "",
        city_id: "",
        area_id: "",
        service_data:[],
        adds:[],
        form: {
          warehouse_id: "",
          logistic_id:'',
          first_weight:1,
          first_weight_price:1,
          continuous_weight:'',
          continuous_price:'',
          free_amount:0,
          adds:[
            {
              province_id:'',
              city_id:'',
              area_id:'',
              show:true
            }
          ]
        },
        show: true,
        placeholder: "",
        id: "",
        rules: {
          "name": [
            {required: true, message: "请输入商户名称", trigger: "blur"},
          ],
          // "province_id": [
          //   {required: true, message: "请选择省份", trigger: "change"},
          // ],
          // "city_id": [
          //   {required: true, message: "请选择城市", trigger: "change"},
          // ],
          // "area_id": [
          //   {required: true, message: "请选择区域", trigger: "change"},
          // ],
          "warehouse_id": [
            {required: true, message: "请选择仓库", trigger: "change"},
          ],
          "logistic_id": [
            {required: true, message: "请选择物流公司", trigger: "change"},
          ],
          "first_weight": [
            {required: true, message: "请输入首重重量", trigger: "blur"},
          ],
          "first_weight_price": [
            {required: true, message: "请输入首重价格", trigger: "blur"},
          ],
          "continuous_weight": [
            {required: true, message: "请输入续重单位", trigger: "blur"},
          ],
          "continuous_price": [
            {required: true, message: "请输入续重价格", trigger: "blur"},
          ],
        },
        innerVisible:false
      };
    },

    computed: {},

    mounted() {
      _this = this;
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        getCompaniesListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.companyOptions = res.data
          }
        })
        getWarehousesListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.warehouseOptions = res.data
          }
        })
      },
      addArea(){
        let params = {
          province_id:'',
          city_id:'',
          area_id:0,
          show:true
        }
        this.form.adds.push(params)
      },
      deleteAdd(k){
        console.log(k)
        this.form.adds[k].show=false
        this.form.adds.forEach(item=>{
          if(item.show==true){
            this.adds.push(item)
          }
        })
        // this.form.adds = adds
        console.log(this.adds)
        // console.log(this.form.adds)
      },
      handleAreaDistrictsChoose(k,e){
        console.log(e)
        console.log(k)
        this.form.adds[k].province_id = e.province_code
        this.form.adds[k].city_id = e.city_code;
        if(e.area_code == ''){
          this.form.adds[k].area_id = 0;
        }else{
          this.form.adds[k].area_id = e.area_code;
        }
        console.log(this.form.adds)
      },
      provinceClick(){
        let that = this
        if(this.form.province_id === ''){
          this.cityList = [];
          this.areaList = [];
          this.form.city_id = ''
          this.form.area_id=''
          return false;
        }
        let province_id = ''
        this.provinceList.forEach(item => {
          if(item.id == that.form.province_id ){
            province_id = item.province_id
            getCity(province_id).then(res=>{
              if(res.code === 200){
                this.cityList = res.data
              }
            })
          }
        });
      },
      cityClick(){
        let that = this
        if(this.form.city_id === ''){
          this.areaList = [];
          this.form.area_id=''
          return false;
        }
        let city_id
        this.cityList.forEach(item => {
          if(item.id == that.form.city_id ){
            city_id = item.city_id
            getArea(city_id).then(res=>{
              if(res.code === 200){
                console.log(res.data)
                this.areaList = res.data
              }
            })
          }
        });
      },


      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.form.adds=this.adds
        this.form.free_amount = parseFloat(this.form.free_amount).toFixed(2)
        console.log(this.form)
        this.$refs[form].validate(valid => {
          if (valid) {
            addPriceMulti( _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            });
            this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
