<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">仓库信息</el-divider>
      <el-form-item label="仓库名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入仓库名称"></el-input>
      </el-form-item>
      <el-form-item label="仓库详细地址" prop="address" require>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          ></el-option>
        </el-select>
        <div v-if="keywordProvince!==''">{{keywordProvince}}</div>
      </el-form-item>
      <el-form-item label="代理商" prop="agent_id">
        <el-select v-model="form.agent_id" clearable filterable placeholder="请选择代理商" style="width:100%">
          <el-option
            v-for="(value,key) in agentOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input placeholder="请输入联系方式" v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {getWarehousesListSelect,addWarehouse} from "@/api/stocks.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      row: Object,
      editId: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        agentOptions: [],
        toolsConfig: ["districts"],
        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',
        form: {
          name: "",
          phone: "",
          province_id: "",
          city_id: "",
          area_id: "",
          address: "",
          lat:'',
          lng:'',
          agent_id:0
        },
        imageUrl: [],
        storeImageUrl:[],
        value: [],
        time: '',
        serviceCascader: [],
        serviceCascaderProps: {
          multiple: true,
          value:'id',
          label:'label'
        },
        serviceCascaderValue:[],
        service_data:{
          service_ids:[],
          service_ids_name:[],
          service_province:'',
          service_city:'',
          service_area:'',
          service_province_str:'',
          service_city_str:'',
          service_area_str:''
        },
        show: true,
        placeholder: "",
        id: "",
        rules: {
          "name": [
            {required: true, message: "请输入仓库名称", trigger: "blur"},
          ],
          "address": [
            {required: true, message: "请输入仓库地址", trigger: "blur"},
          ],
          "phone": [
            {required: true, message: "请输入联系方式", trigger: "blur"},
          ],
        },
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        // getAgentSelect().then(res=>{
        //   if(res.code === 200){
        //     this.agentOptions = res.data
        //     console.log(this.agentOptions)
        //   }
        // })
      },
      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
            this.form.lat = data["location"]["lat"];
            this.form.lng = data["location"]["lng"];
            this.form.address = data['address']
          }
        })
      },

      serviceCascaderChange(e){
        let ids = []
        let names = []
        let object = this.$refs["service_cascader"].getCheckedNodes()
        Object.keys(object).map((key) => [object[key], key]).forEach(([value,key]) => {
          //循环操作
          if(!value.hasChildren){
            ids.push(value.value)
            names.push(value.label)
          }
        })
        this.service_data.service_ids = ids
        this.service_data.service_ids_name = names
      },

      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            console.log(_this.form)
            addWarehouse( _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "添加成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            });
            this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
      addServiceData(){
        this.form.service_data.push(JSON.parse(JSON.stringify(this.service_data)));
        this.serviceCascaderValue = []
        this.innerVisible = false
      },
      // 选择省市区
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province_code;
        _this.form.city_id = e.city_code;
        _this.form.area_id = e.area_code;
      },
      handleAreaDistrictsChoose(e){
        this.service_data.service_province = e.province_code
        this.service_data.service_province_str = e.province
        this.service_data.service_city = e.city_code
        this.service_data.service_city_str = e.city
        this.service_data.service_area = e.area_code
        this.service_data.service_area_str = e.area
      },
      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.license = e[0];
      },
      handleLicenseRemove(e) {
        this.form.license = "";
      },
      // 上传图片删除、缓存、预览
      handlePicSuccess(e) {
        this.form.storeImg = e;
      },
      handlePicRemove(e) {
        this.form.storeImg = "";
      },

      removeServiceData(key){
        console.log(key)
        let object = JSON.parse(JSON.stringify(this.form.service_data))
        console.log(object)
        if(object.length ===1){
          object = []
        }else{
          object.splice(key,1)
        }
        this.form.service_data = object
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
