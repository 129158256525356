<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-form-item label="代理商名称" prop="name" required>
        <el-input v-model="form.name" placeholder="请输入代理商名称"></el-input>
      </el-form-item>
      <el-form-item label="代理类型" prop="type" required>
        <el-radio v-model="form.type" :label="0">个人</el-radio>
        <el-radio v-model="form.type" :label="1">企业</el-radio>
      </el-form-item>
      <el-form-item label="代理级别" prop="level_id" required>
        <el-select v-model="form.level_id" placeholder="请选择代理级别">
          <el-option
            v-for="(value,key) in levels"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号，可做为代理商管理系统账号"></el-input>
      </el-form-item>

      <el-form-item label="详细地址" prop="address" require>
        <el-select
          style="width: 100%"
          v-model="keywordResult"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          @change="keywordChange"
          :remote-method="remoteMethod"
          :loading="keywordLoading"
        >
          <el-option
            v-for="(value,key) in keywordAddress"
            :key="key"
            :label="value.title"
            :value="key"
          >
            <span style="float:left;margin-right:20px;">{{ value.title }}</span>
            <span style="float:right; color: #8492a6; font-size: 12px">{{ value.address }}</span></el-option>
          
        </el-select>
        <div v-if="keywordProvince!==''">{{keywordProvince}}</div>
      </el-form-item>

      <el-form-item label="身份证号码" prop="liscense_no" v-if="form.type === 0">
        <el-input v-model="form.liscense_no" placeholder="请输入身份证号码"></el-input>
      </el-form-item>
      <el-form-item label="信用代码" prop="liscense_no" v-if="form.type === 1">
        <el-input v-model="form.liscense_no" placeholder="请输入信用代码"></el-input>
      </el-form-item>
      <el-form-item label="身份证正面照片" v-if="form.type === 0">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" :multiple="false" :images="imageUrl"></upload-qiniu>
      </el-form-item>
      <el-form-item label="身份证反面照片" v-if="form.type === 0">
        <upload-qiniu @success="handleLicenseBackSuccess" @remove="handleLicenseBackRemove" :multiple="false" :images="imageUrlBack"></upload-qiniu>
      </el-form-item>
      <el-form-item label="上传营业执照" v-if="form.type === 1">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" :multiple="false" :images="imageUrl"></upload-qiniu>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="submit('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
  let _this;
  import {getLevelListSelect,addData} from "@/api/agents.js";
  import {tencentAddressKeyword,areaDetailCode} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  export default {
    components: { UploadQiniu},
    data() {
      return {
        form: {
          name: "",
          type: 0,
          province_id: "",
          city_id:'',
          area_id:'',
          address:"",
          level_id:"",
          phone:'',
          status:0,
          liscense:'',
          liscense_back:'',
          liscense_no:''
        },

        keywordAddress: [],
        keywordLoading: false,
        keywordResult: "",
        keywordProvince:'',

        levels:[],
        imageUrl: [],
        imageUrlBack:[],
        rules: {
          name: [
            {required: true, message: "请输入代理商名称", trigger: "blur"},
          ],
          phone: [
            {required: true, message: "请输入手机号", trigger: "change"},
          ],
          address: [
            {required: true, message: "请输入地址", trigger: "blur"},
          ],
        },
        
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();
      this.form.group_id = this.$store.state.user.userData.group_id
    },

    methods: {
      // 初始化数据
      init() {
        getLevelListSelect().then(res=>{
          if(res.code === 200){
            this.levels = res.data
          }
        })
        
      },

      remoteMethod(query) {
        if (query !== "") {
          this.keywordLoading = true;
          tencentAddressKeyword({keyword: query}).then(res => {
            this.keywordLoading = false;
            if (res.code === 200) {
              this.keywordAddress = res.data;
              console.log(res.data)
            }
          });
        } else {
          this.options = [];
        }
      },

      keywordChange() {
        let data = this.keywordAddress[this.keywordResult];
        this.keywordProvince = data['address']
        areaDetailCode({area_id: data.adcode}).then(res => {
          if (res.code === 200) {
            this.form.province_id = res.data.province_id
            this.form.city_id = res.data.city_id
            this.form.area_id = res.data.area_id
            this.form.lat = data["location"]["lat"];
            this.form.lng = data["location"]["lng"];
            this.form.address = data['address']
          }
        })
      },

      handleLicenseSuccess(e) {
        console.log(e)
        this.imageUrl = e;
        this.form.license = e[0];
      },
      handleLicenseRemove(e) {
        this.imageUrl = [];
        this.form.license = "";
      },
      handleLicenseBackSuccess(e) {
        console.log(e)
        this.imageUrlBack = e;
        this.form.license_back = e[0];
      },
      handleLicenseBackRemove(e) {
        this.imageUrlBack = [];
        this.form.license_back = "";
      },
      
      close() {
        _this.$emit("close");
      },
      submit(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            addData(_this.form).then(res => {
              if (res.code === 200) {
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            })
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
