<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      :rules="rules"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">入库信息</el-divider>
      <el-form-item label="商品" prop="goods_name" >
        <el-input v-model="form.goods_name" placeholder="请选择商品" @focus="dialogVisible=true"></el-input>
      </el-form-item>
      <el-form-item label="库存" prop="count" required>
        <el-input v-model="form.count" placeholder="请输入库存"></el-input>
      </el-form-item>
      <el-form-item label="进价" prop="purchase_price" required>
        <el-input v-model="form.purchase_price" placeholder="请输入进价"></el-input>
      </el-form-item>
      <el-form-item label="代理价" prop="agent_price" required>
        <el-input v-model="form.agent_price" placeholder="请输入代理价"></el-input>
      </el-form-item>
      <el-form-item label="零售价" prop="price" required>
        <el-input v-model="form.price" placeholder="零售价"></el-input>
      </el-form-item>
      <el-form-item label="仓库" prop="warehouse_id">
        <el-select v-model="form.warehouse_id" clearable filterable placeholder="请选择仓库" style="width:100%">
          <el-option
            v-for="(value,key) in warehouseOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="包装类型" prop="chest_ids">
        <el-select v-model="form.chest_ids" multiple clearable filterable placeholder="请选择包装" style="width:100%">
          <el-option
            v-for="(value,key) in chestOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否上架" prop="status">
        <el-radio-group v-model="form.status" size="small">
          <el-radio :label="0" border>未上架</el-radio>
          <el-radio :label="1" border>已上架</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代理商" prop="agent_id">
        <el-select v-model="form.agent_id" clearable filterable placeholder="请选择代理商" style="width:100%">
          <el-option
            v-for="(value,key) in agentOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="购买单位" prop="unit" required>
        <el-select v-model="form.unit" clearable filterable placeholder="请选择购买单位" style="width:100%">
          <el-option
            v-for="(value,key) in unitOptions"
            :key="key"
            :label="value.label"
            :value="value.value">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="仓库类型" prop="type" required>
        <el-select v-model="form.type" clearable filterable placeholder="请选择仓库类型" style="width:100%">
          <el-option
            v-for="(value,key) in typeOptions"
            :key="key"
            :label="value.label"
            :value="value.value">
          </el-option>
        </el-select>
      </el-form-item> -->
     
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addData('form')" :plain="true">立即添加</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      width="60%"
      title="商品"
      :visible.sync="dialogVisible"
      append-to-body>
        <div class="icon-dialog">
          <div class="icon-dialog-list" v-for="(value,key) in goodsList" :key="key" @click="chooseGoods(value)">
            <el-image 
              style="width: 100px; height: 100px"
              :src="value.images[0]" 
              fit="cover">
            </el-image>
            <p class="icon-name">{{value.name}}</p>
            <p class="icon-name">{{value.price}}</p>
          </div>
        </div>
    </el-dialog>
  </section>
</template>

<script>

  let _this;
  let id = 0;
  import {addStocks,getWarehousesListSelect,getChestListSelect} from "@/api/stocks.js";
  import {getList} from "@/api/goods.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      row: Object,
      editId: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        agentOptions: [],
        chestOptions:[],
        warehouseOptions: [],
        unitOptions: [{
          value: 0,
          label: '1瓶装'
        }, {
          value: 1,
          label: '2瓶装'
        }, {
          value: 2,
          label: '3瓶装'
        }, {
          value: 3,
          label: '6瓶装'
        }],
        typeOptions: [{
          value: 0,
          label: '本地仓库'
        }, {
          value: 1,
          label: '云仓'
        }],
        toolsConfig: ["districts"],
        form: {
          goods_id:'',
          status: "",
          warehouse_id: "",
          agent_id: 0,
          unit:'',
          type:'',
          purchase_price:''
        },
        id: "",
        rules: {
          "name": [
            {required: true, message: "请输入名称", trigger: "blur"},
          ],
          "count": [
            {required: true, message: "请输入库存", trigger: "blur"},
          ],
          "warehouse_id": [
            {required: true, message: "请选择仓库", trigger: "change"},
          ],
          "unit": [
            {required: true, message: "请选择单位", trigger: "change"},
          ],
          "type": [
            {required: true, message: "请选择类型", trigger: "change"},
          ],
          "purchase_price": [
            {required: true, message: "请输入进价", trigger: "blur"},
          ],
          "agent_price": [
            {required: true, message: "请输入代理价", trigger: "blur"},
          ],
        },
        
        dialogVisible:false,
        goodsList:[]
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        getWarehousesListSelect().then(res=>{
          if(res.code === 200){
            this.warehouseOptions = res.data
            console.log(this.warehouseOptions)
          }
        })
        getChestListSelect().then(res=>{
          if(res.code === 200){
            this.chestOptions = res.data
            console.log(this.chestOptions)
          }
        })
        getList().then(res=>{
          if(res.code === 200){
            this.goodsList = res.data.data
            console.log(this.goodsList)
          }
        })
      },

      chooseGoods(e){
        this.form.goods_id = e.id
        this.form.goods_name = e.name
        this.dialogVisible=false
      },
      close() {
        _this.$emit("close");
      },

      addData(form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            console.log(this.form)
            addStocks( _this.form).then((res) => {
              if (res.code === 200) {
                _this.$message({
                  message: "入库成功",
                  type: "success"
                });
                setTimeout(() => {
                  _this.$emit("success");
                }, 1000);
              }
            });
            this.form = form
          } else {
            console.log("error submit!!");
            return false;
          }
        })
      },
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 10px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  .icon-name{
    margin-top:10px;
    font-size: 12px;
  }
</style>
