<template>
  <section>
    <div class="container">
      <div class="search-box" style="padding-top:20px;"><h1>订单列表</h1></div>
      <div class="control-box" style="margin-top: 10px;display: flex; justify-content: flex-start;" >
            <el-input class="control-box-input" style="width:200px;" v-model="params.order_number" placeholder="请输入订单号" clearable></el-input>
            
            <el-select v-model="params.status" placeholder="请选择状态" clearable style="margin-left:10px;">
              <el-option
                v-for="item in orderType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-button icon="el-icon-search" @click="getTable" style="margin-left:10px;">搜索</el-button>
          </div>
      <!--订单列表-->
      <el-table :data="table" class="table">

        <!-- 数据展示区 -->
        <el-table-column
          label="订单号"
          prop="serial"
          align="left"
          width="180"
        ></el-table-column>
        <el-table-column
          label="产品列表"
          prop="goods_detail"
          align="left"
          width="280"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div style="width:400px;">
                <div style="display:inline-flex;align-items: center;width:100%; border-bottom: 1px solid rgba(0,0,0,0.2);padding-bottom:10px;margin-top:10px;" v-for="(value,key) in scope.row.goods_detail">
                  <div style="width: 100px;height:100px;overflow: hidden;"><el-image style="width:100px;heigth:100px;" fit="cover" :src="value.stock.goods.images.length>0 ? value.stock.goods.images[0] : '' "></el-image></div>
                  <div style="margin-left:20px;">{{ value.stock.goods.name }}</div>
                  <div style="margin-left:20px;">数量：{{ value.quantity }}</div>
                </div>
              </div>
              <el-button size="mini" slot="reference">{{scope.row.goods_detail[0].stock.goods.name}}等，查看详情</el-button>
            </el-popover>
          </template>
        </el-table-column>
        
        <el-table-column label="用户" prop="user_id" align="left" width="140">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>ID: {{ scope.row.user.id }}</p>
              <p>姓名: {{ scope.row.user_address.name }}</p>
              <p>手机号: {{ scope.row.user.phone }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.user_address.name}}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          label="付款金额"
          prop="amount"
          align="left"
          width="100"
        ></el-table-column>
        <el-table-column
          label="快递单号"
          prop="express_no"
          align="left"
          width="180"
        ></el-table-column>
        <el-table-column
          label="运费"
          prop="express_price"
          align="left"
          width="100"
        ></el-table-column>
        <el-table-column
          label="发票"
          prop="invoice"
          align="left"
          width="155"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.is_invoice ===1">
              <p>ID: {{ scope.row.user.id }}</p>
              <p>姓名: {{ scope.row.user.nickname }}</p>
              <p>手机号: {{ scope.row.user.phone }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.user.phone}}</el-tag>
              </div>
            </el-popover>
            <span v-else>未开发票</span>
          </template>
        </el-table-column>
        <el-table-column
          label="购买方式"
          prop="type"
          align="left"
          width="155"
        >
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.type===0">线上</el-tag>
            <el-tag v-if="scope.row.type===1">线下</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="运费结算"
          prop="type"
          align="left"
          width="155"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.express_type===0">线上付款</span>
            <span v-if="scope.row.express_type===1">货到付款</span>
          </template>
        </el-table-column> -->
        <el-table-column
          label="订单状态"
          prop="status"
          align="left"
          width="140"
        >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status===0 || scope.row.status===1" size="mini"> 未付款</el-tag>
            <el-tag v-else size="mini">{{ scope.row.status_zh }}</el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          label="收货地址"
          prop="address"
          align="left"
          width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.province_name }}{{ scope.row.city_name }}{{ scope.row.area_name }}{{ scope.row.address }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="订单时间"
          prop="created_at"
          align="left"
          width="155"
        ></el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="100">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-around;
                "
              >
                <!-- <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>-->
                <!-- <el-link style="color: #409eff;" @click="express(scope.row)">
                  <el-tooltip class="item" effect="dark" content="修改快递单号" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>  -->
                <el-link style="color: #409eff;" @click="outBound(scope.row)" v-if="scope.row.status>1">
                  <el-tooltip class="item" effect="dark" content="出库" placement="top-start">
                    <i class="el-icon-document-remove" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link> 
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">

                    <el-dropdown-item command="cancel" divided>取消订单</el-dropdown-item>
                    <el-dropdown-item command="confirm" v-if="scope.row.type===1" divided>确认付款</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
      </el-table>

      <!--分页-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right"
        ></el-pagination>
      </el-col>
    </div>

    <el-dialog
      title="修改快递单号"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="display:flex;justify-content: flex-start;align-items: center;">
        <div style="width:80px;">快递公司：</div>
        <div v-if="logistics_company_id>0" style="width:60%;margin-left:10px;">{{ logistics_company }}</div>
        <el-select v-else v-model="logistics_choose_id" placeholder="请选择快递公司" clearable style="width:60%;margin-left:10px;">
          <el-option
            v-for="item in logisticsList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
        <div style="width:80px;">快递号：</div>
        <el-input v-model="express_no" placeholder="请输入快递单号" style="width:60%;margin-left:10px;"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getTable, updateExpress,updateStatus,outBound,confirmPay} from "@/api/order.js";
import { getCompaniesListSelect} from "@/api/logistics.js";
import ToolsBox from "@/components/tool/Index.vue";

export default {
  name: "App",
  components: { ToolsBox },
  data() {
    return {
      toolsConfig: ["searchOrderType", "searchOrderNum"],
      outerVisible:false,
      params: {
        page:1,
        order_number:'',
        status:''
      },
      logisticsList:[],
      dialogVisible:false,
      express_no:'',
      express_id:'',
      logistics_company:'',
      logistics_company_id:'',
      logistics_choose_id:'',
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      showBack: false,
      appointID:0,

      orderType:[
        {
          value: 0,
          label: "初始化",
        },
        {
          value: 1,
          label: "接单/派单",
        },
        {
          value: 2,
          label: "执行中",
        },
        {
          value: 3,
          label: "付款",
        },
        {
          value: 4,
          label: "结束",
        },
        {
          value: 9,
          label: "取消",
        }
      ]
    };
  },
  methods: {
    // 获取订单列表
    getTable() {
      console.log(1341234)
      getTable(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.table = res.data.data
          this.total = res.data.total;
        }
      });
      getCompaniesListSelect().then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.logisticsList = res.data
        }
      });
    },

    cancel(){
      this.dialogVisible=false
    },
    confirm(){
      let _this = this
      if(this.express_no==='' ){
        return _this.$confirm('请输入快递号')
      }
      
      updateExpress(this.express_id,{'express_no':this.express_no,'logistics_company_id':this.logistics_company_id}).then((res) => {
        if (res.code === 200) {
          this.dialogVisible=false      
          _this.$message({
            message: "修改成功",
            type: "success"
          });
          this.express_id = 0
          this.express_no = ''
          _this.getTable()
        }
      });
    },

    // 关闭添加/修改
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.dialogVisible = false
        })
        .catch((_) => {});
    },
    express(row){
      this.express_id = row.id
      this.logistics_company_id = row.logistics_company_id
      if(row.logistics_company){
        this.logistics_company = row.logistics_company.name
      }
      this.dialogVisible = true
    },
    outBound(row){
      console.log(row.id)
      
      this.$confirm("确认生成出库单吗？")
        .then((_) => {
          outBound(row.id).then(res =>{
            if (res.code===200){
              this.getTable();
              this.$message({
                type: 'success',
                message: '已生成出库单!'
              });
            }
          })
        })
        .catch((_) => {});
      
    },
    command(e,row){
      
      if(e === 'express'){
        this.dialogVisible = true
      }

      if(e === 'cancel'){
        this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateStatus(row.id,{status:9}).then(res =>{
            if (res.code===200){
              this.getTable();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          })
        }).catch(() => {
        });
      }
      if(e === 'confirm'){
        this.$confirm('此操作将修改订单状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          confirmPay(row.id,{status:9}).then(res =>{
            if (res.code===200){
              this.getTable();
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
            }
          })
        }).catch(() => {
        });
      }
    },
    changePrice(){
      editData(this.formPrice.id,{'price':this.formPrice.price}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.priceVisible = false
        }
      })
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    // 查询
    getOrderType(type) {
      console.log(type)
      this.params.status = type;
    },
    getOrderNum(number) {
      this.params.order_number = number;
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },

    goAppoint(){
      let company = this.cascaderValue[0] ? 0 : this.cascaderValue[0]
      goAppoint(this.appointID,{company_id:company, provider:this.cascaderValue[1]}).then(res=>{
        if (res.code === 200){
          this.$message({
            type: 'success',
            message: '指派成功!'
          });
          this.outerVisible = false
          this.getTable()
        }
      })
    }
  },
  mounted() {
    this.getTable();
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
