<template>
  <section style="padding:0 5%; height: 100%;">
    <el-form ref="form" label-position="top" status-icon :model="form" :rules="rules" label-width="100px"
             style="width:100%;height: calc(100% - 20px);overflow:auto">
      <el-form-item label="名称" prop="name" required>
        <el-input placeholder="请输入名称" v-model="form.name">
        </el-input>
      </el-form-item>
      <el-form-item label="国家" prop="country_id">
        <el-select v-model="form.country_id" filterable placeholder="请选择国家" clearable @clear="handleStoreClear">
          <el-option label="请选择国家" :value="0">请选择国家</el-option>
          <el-option
            v-for="(value,key) in countries"
            :key="value.id"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="历史" prop="history" required>
        <el-input  type="textarea" placeholder="请输入历史" v-model="form.history">
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addAssistant('form')" :plain='true'>立即添加</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addArea,getCountryListSelect} from '@/api/goods.js';
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
 
  export default {
    components: {
      UploadQiniu
    },
    data() {
      return {
        form: {
          name: '',
          country_id: '',
          history: '',
        },
        imageUrl:[],
        countries:[],
        rules: {
          name: [
            {required: true, message: "请输入商品产区名称", trigger: "blur"},
            {required: true, message: "请输入商品国家", trigger: "blur"},
          ]
        },
      };
    },
    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      init() {
        getCountryListSelect().then(res=>{
          if(res.code === 200){
            this.countries = res.data
          }
        })
      },
      // 数据初始化
      close() {
        _this.$emit('close');
      },

      agentChange(e){
        if(e && !this.form.idcard){
          this.$message.error("请先完成认证")
          this.form.is_agent = 0;
        }
      },
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.image = e[0];
      },
      handleLicenseRemove(e) {
        this.form.image = "";
      },

      //  添加
      addAssistant(form) {

        _this.$refs["form"].validate(valid => {
          if (valid) {
            console.log(_this.form)
            addArea(_this.form)
              .then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
              .catch(error => {
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      handleDistrictsChoose(e) {
        
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-drawer {
    overflow: hidden;
  }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
