<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>出库单列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入主题/单号" clearable></el-input>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="ID" prop="id" align="center" width="120"></el-table-column>
          <el-table-column label="出库主题" prop="name" align="center" width="120"></el-table-column>
          <el-table-column label="出库日期" prop="updated_at" align="center"></el-table-column>
          <el-table-column label="出库仓库" prop="warehouse.name" align="center"></el-table-column>
          <el-table-column label="对应客户" prop="user.name" align="center"></el-table-column>
          <el-table-column label="出库状态" prop="status" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 0">未出库</span>
              <span v-if="scope.row.status === 1">已出库</span>
            </template>
          </el-table-column>
          <el-table-column label="付款方式" prop="order.type" align="center">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.order.type === 0">线上付款</el-tag>
              <el-tag v-if="scope.row.order.type === 1">线下付款</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="总重量" prop="weight_total" align="center"></el-table-column>

          <el-table-column label="操作" width="120" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" @click="express(scope.row)" v-if="scope.row.status === 1">
                  <el-tooltip class="item" effect="dark" content="修改快递单号" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link> 
                <el-link style="color: #409eff;" @click="editTable(scope.row.id)" v-if="scope.row.status===0">
                  <el-tooltip class="item" effect="dark" content="确认出库" placement="top-start">
                    <i class="el-icon-document-add" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="editComment(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="备注" placement="top-start">
                    <i class="el-icon-tickets" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="sendList(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查发货" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
      <el-dialog
        title="添加备注"
        :visible.sync="dialogCommentVisible"
        width="30%"
        :before-close="handleClose">
        <div style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:80px;">备注：</div>
          <el-input v-model="commentDetail" placeholder="请输入备注" type="textarea" style="width:60%;margin-left:10px;"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="commentCancel()">取 消</el-button>
          <el-button type="primary" @click="commentConfirm()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="修改快递单号"
        :visible.sync="dialogNumVisible"
        width="30%"
        :before-close="handleClose">
        <div style="display:flex;justify-content: flex-start;align-items: center;">
          <div style="width:80px;">快递公司：</div>
          <el-select v-if="logistics_company_id>0" v-model="logistics_choose_id" disabled style="width:60%;margin-left:10px;">
            <el-option
              v-for="item in logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
          <el-select v-else v-model="logistics_choose_id" placeholder="请选择快递公司" clearable style="width:60%;margin-left:10px;" @change="logisticsChange($event)">
            <el-option
              v-for="item in logisticsList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div v-if="logistics_company_id>0" style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:80px;">快递费：</div>
          <div style="margin-left:10px;">{{ express_price }}元</div>
        </div>
        <div v-if="logistics_company_id===0" style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:80px;">快递费：</div>
          <el-input v-model="express_price" placeholder="请输入快递费" style="width:60%;margin-left:10px;"></el-input>
        </div>
        <div style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:80px;">快递号：</div>
          <el-input v-model="express_no" placeholder="请输入快递单号" style="width:60%;margin-left:10px;"></el-input>
        </div>
        <div style="display:flex;justify-content: flex-start;align-items: center;margin-top:20px;">
          <div style="width:80px;">备注：</div>
          <el-input v-model="comment" placeholder="请输入备注" type="textarea" style="width:60%;margin-left:10px;"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel()">取 消</el-button>
          <el-button type="primary" @click="confirm()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog width="60%" align="center" :visible.sync="dialogVisible" append-to-body>
        <send-list :id="stockOutID" v-if="dialogVisible"></send-list>
      </el-dialog>
    </div>


  </section>
</template>

<script>
  import { getWarehousesList, deleteWarehouse,getOutBound,outStockConfirm,outStockComment} from "@/api/stocks.js";
  import { updateExpress} from "@/api/order.js";
  import { getCompaniesListSelect} from "@/api/logistics.js";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import sendList from "@/views/components/sendList.vue";

  let page_name = 'store_list'
  export default {
    name: "App",
    components:{addComp,editComp,sendList},
    data() {
      return {
        percent:'',
        dialogVisible:false,
        dialogNumVisible:false,
        dialogCommentVisible:false,
        admin_permission:[],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          name:'',
          province_id:'',
          city_id:'',
          area_id:'',
        },
        groupList:[],
        groupID:'',
        table:[],
        service: [],
        editID: '',
        total: 0,
        stockInID:'',
        stockOutID:'',
        commentId:'',
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        
        express_no:'',
        express_id:'',
        express_price:'',
        logistics_company:'',
        logistics_company_id:'',
        logistics_choose_id:'',
        comment:"",//快递单号备注
        commentDetail:'',//出库备注
        logisticsList:[],
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        },
        gridData:[]
      };
    },

    methods: {
      getTableData() {
        getOutBound(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
        
        getCompaniesListSelect().then((res) => {
          if (res.code === 200) {
            console.log(res.data)
            this.logisticsList = res.data
          }
        });
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },
      //弹出修改快递单号
      express(row){
        this.express_id = row.order.id
        this.express_no = row.order.express_no
        this.express_price = row.order.express_price
        if(this.express_price=='0.00'){
          this.express_price=''
        }
        this.logistics_company_id = row.order.logistics_company_id
        this.logistics_choose_id = row.order.logistics_company_id
        if(this.logistics_choose_id===0){
          this.logistics_choose_id = ''
        }
        // this.logisticsList.forEach(item=>{
        //   if(item.id === this.logistics_company_id){
        //     this.logistics_company = item.name
        //   }
        // })
        this.dialogNumVisible = true
      },
      editComment(e){
        this.dialogCommentVisible = true
        this.commentId = e
      },
      commentConfirm(){
        let _this = this
        outStockComment(this.commentId,{'comment':this.commentDetail}).then(res=>{
          if(res.code === 200){
            this.dialogCommentVisible=false      
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            this.commentDetail = ''
            _this.getTableData()
          }
        })
      },
      //出库备注取消
      commentCancel(){
        this.dialogCommentVisible=false
      },
      logisticsChange(e){
        console.log(e)
        this.logistics_choose_id = e
      },
      //修改快递单号取消
      cancel(){
        this.dialogNumVisible=false
      },
      //修改快递单号确定
      confirm(){
        let _this = this
        if(this.express_no==='' ){
          return _this.$confirm('请输入快递号')
        }
        
        updateExpress(this.express_id,{'express_no':this.express_no,'express_price':this.express_price,'logistics_company_id':this.logistics_choose_id,'comment':this.comment}).then((res) => {
          if (res.code === 200) {
            this.dialogNumVisible=false      
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            this.express_id = 0
            this.express_no = ''
            _this.getTableData()
          }
        });
      },
      // 删除
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteWarehouse(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogNumVisible = false;
            this.dialogCommentVisible=false
          })
          .catch((_) => {});
      },
      sendList(id){
        console.log(id)
        this.dialogVisible = true
        this.stockOutID = id
      },
      editTable(id){
        outStockConfirm(id).then(res=>{
          if(res.code === 200){
            this.$confirm("确认出库吗？")
              .then((_) => {
                this.$notify({
                title: '成功',
                message: '出库成功',
                type: 'success'
              });
              this.getTableData()
              })
              .catch((_) => {});
            
          }
        })
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
