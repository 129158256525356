<template>
  <section style="padding:0 5%; height: 100%;">
    <el-form ref="form" label-position="top" status-icon :model="form" :rules="rules" label-width="100px"
             style="width:100%;height: calc(100% - 20px);overflow:auto">
      <el-form-item label="名称" prop="name" required>
        <el-input placeholder="请输入名称" v-model="form.name">
        </el-input>
      </el-form-item>
      <el-form-item label="是否更换泡沫" prop="foam" required>
        <el-radio-group v-model="form.foam" size="small">
          <el-radio :label="1" border>是</el-radio>
          <el-radio :label="0" border>否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否有托盘" prop="tray" required>
        <el-radio-group v-model="form.tray" size="small">
          <el-radio :label="1" border>是</el-radio>
          <el-radio :label="0" border>否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="类型" prop="type" required>
        <el-radio-group v-model="form.type" size="small">
          <el-radio :label="0" border>快递</el-radio>
          <el-radio :label="1" border>物流</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addAssistant('form')" :plain='true'>立即添加</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addCompany} from '@/api/logistics.js';
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
 
  export default {
    components: {
      UploadQiniu
    },
    data() {
      return {
        form: {
          name: '',
          foam: '',
          tray: '',
          type:0,
        },
        imageUrl:[],
        countries:[],
        rules: {
          name: [
            {required: true, message: "请输入公司名称", trigger: "blur"},
          ],
          foam: [
            {required: true, message: "请选择是否更换泡沫", trigger: "change"},
          ],
          tray: [
            {required: true, message: "请选择是否有托盘", trigger: "change"},
          ],
          type: [
            {required: true, message: "请选择公司类型", trigger: "change"},
          ],
        },
      };
    },
    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      init() {
      },
      // 数据初始化
      close() {
        _this.$emit('close');
      },


      //  添加
      addAssistant(form) {

        _this.$refs["form"].validate(valid => {
          if (valid) {
            console.log(_this.form)
            addCompany(_this.form)
              .then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
              .catch(error => {
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      handleDistrictsChoose(e) {
        
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-drawer {
    overflow: hidden;
  }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
