import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``


export function getWarehousesList(data = {}) {
  return axios.request({
    url: `${url}/stocks/warehouses`,
    method: "get",
    params: data,
  })
}
export function getStockListSelect(data = {}) {
  return axios.request({
    url: `${url}/stocks/select`,
    method: "get",
    params: data,
  })
}
export function getWarehousesListSelect(data = {}) {
  return axios.request({
    url: `${url}/stocks/warehouses/select`,
    method: "get",
    params: data,
  })
}
export function getWarehouseById(id,params={}){
  return axios.request({
    url: `${url}/stocks/warehouse/${id}`,
    method: "get",
    params,
  })
}

export function deleteWarehouse(id,data) {
  return axios.request({
    url: `${url}/stocks/warehouse/${id}`,
    method: "delete",
    params:data,
  })
}
export function addWarehouse(data) {
  return axios.request({
    url: `${url}/stocks/warehouse`,
    method: "post",
    data: data,
  })
}
export function editWarehouse(id, data) {
  return axios.request({
    url: `${url}/stocks/warehouse/${id}`,
    method: "put",
    data: data,
  })
}


export function getStocks(data = {}) {
  return axios.request({
    url: `${url}/stocks`,
    method: "get",
    params: data,
  })
}
export function getStocksListSelect(data = {}) {
  return axios.request({
    url: `${url}/stocks/warehouses/select`,
    method: "get",
    params: data,
  })
}
export function getStockById(id,params={}){
  return axios.request({
    url: `${url}/stock/${id}`,
    method: "get",
    params,
  })
}

export function deleteStock(id,data) {
  return axios.request({
    url: `${url}/stock/${id}`,
    method: "delete",
    params:data,
  })
}
export function addStocks(data) {
  return axios.request({
    url: `${url}/stock`,
    method: "post",
    data: data,
  })
}
export function editStock(id, data) {
  return axios.request({
    url: `${url}/stock/${id}`,
    method: "put",
    data: data,
  })
}

export function setStatus(id, data) {
  return axios.request({
    url: `${url}/stock/status/${id}`,
    method: "put",
    data: data,
  })
}
//上架
export function inStocks(id, data) {
  return axios.request({
    url: `${url}/stock/status/${id}`,
    method: "put",
    data: data,
  })
}
//入库
export function inStorage(data) {
  return axios.request({
    url: `${url}/stock/put/in`,
    method: "post",
    data: data,
  })
}
//获取入库单列表
export function getInStorage(data = {}) {
  return axios.request({
    url: `${url}/stock/put/in`,
    method: "get",
    params: data,
  })
}
//根据ID查询入库单
export function getInStorageById(id,data = {}) {
  return axios.request({
    url: `${url}/stock/put/in/${id}`,
    method: "get",
    params: data,
  })
}
//修改入库单明细
export function editInStorage(id, data) {
  return axios.request({
    url: `${url}/stock/put/in/detail/${id}`,
    method: "put",
    data: data,
  })
}
export function setPutIn(id, data) {
  return axios.request({
    url: `${url}/stock/put/in/end/${id}`,
    method: "put",
    data: data,
  })
}
//出库
export function outStock(id, data) {
  return axios.request({
    url: `${url}/stock/outbound/${id}`,
    method: "put",
    data: data,
  })
}
//获取出库单列表
export function getOutBound(data = {}) {
  return axios.request({
    url: `${url}/stock/put/out`,
    method: "get",
    params: data,
  })
}
//获取包装列表
export function getChestList(data = {}) {
  return axios.request({
    url: `${url}/stocks/chests`,
    method: "get",
    params: data,
  })
}

export function deleteChest(id,data) {
  return axios.request({
    url: `${url}/stocks/chest/${id}`,
    method: "delete",
    params:data,
  })
}
//根据id获取包装列表
export function getChestListSelect(data = {}) {
  return axios.request({
    url: `${url}/stocks/chests/select`,
    method: "get",
    params: data,
  })
}
export function authorize(data) {
  return axios.request({
    url: `${url}/stock/authorize`,
    method: "post",
    data: data,
  })
}
//根据ID查询发货单
export function getsendById(id,data = {}) {
  return axios.request({
    url: `${url}/stock/put/out/${id}`,
    method: "get",
    params: data,
  })
}

//出库
export function outStockConfirm(id, data) {
  return axios.request({
    url: `${url}/stock/put/out/end/${id}`,
    method: "put",
    data: data,
  })
}

//修改包装价格
export function editChestPrice(id,data = {}) {
  return axios.request({
    url: `${url}/stocks/chest/price/${id}`,
    method: "put",
    data: data,
  })
}

//撤销入库
export function cancelById(id,data = {}) {
  return axios.request({
    url: `${url}/stock/put/in/cancel/${id}`,
    method: "get",
    params: data,
  })
}
//出库备注
export function outStockComment(id, data) {
  return axios.request({
    url: `${url}/stock/put/out/comment/${id}`,
    method: "put",
    data: data,
  })
}



