import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/goods`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/good/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/good/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/good`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/good/${id}`,
    method: "put",
    data: data,
  })
}

export function getTagsList(data = {}) {
  return axios.request({
    url: `${url}/goods/tags`,
    method: "get",
    params: data,
  })
}

export function getTagsListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/tags/select`,
    method: "get",
    params: data,
  })
}
export function getTagDataById(id,params={}){
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "get",
    params,
  })
}

export function deleteTagData(id,data) {
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "delete",
    params:data,
  })
}
export function addTagData(data) {
  return axios.request({
    url: `${url}/goods/tag`,
    method: "post",
    data: data,
  })
}
export function editTagData(id, data) {
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "put",
    data: data,
  })
}

export function getTypesList(data = {}) {
  return axios.request({
    url: `${url}/goods/types`,
    method: "get",
    params: data,
  })
}

export function getTypesListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/types/select`,
    method: "get",
    params: data,
  })
}
export function getTypeDataById(id,params={}){
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "get",
    params,
  })
}

export function deleteTypeData(id,data) {
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "delete",
    params:data,
  })
}
export function addTypeData(data) {
  return axios.request({
    url: `${url}/goods/type`,
    method: "post",
    data: data,
  })
}
export function editTypeData(id, data) {
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "put",
    data: data,
  })
}


export function getCountryList(data = {}) {
  return axios.request({
    url: `${url}/goods/countries`,
    method: "get",
    params: data,
  })
}

export function getCountryListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/countries/select`,
    method: "get",
    params: data,
  })
}
export function getCountryDataById(id,params={}){
  return axios.request({
    url: `${url}/goods/country/${id}`,
    method: "get",
    params,
  })
}

export function deleteCountryData(id,data) {
  return axios.request({
    url: `${url}/goods/country/${id}`,
    method: "delete",
    params:data,
  })
}
export function addCountryData(data) {
  return axios.request({
    url: `${url}/goods/country`,
    method: "post",
    data: data,
  })
}
export function editCountryData(id, data) {
  return axios.request({
    url: `${url}/goods/country/${id}`,
    method: "put",
    data: data,
  })
}

export function getAreaList(data = {}) {
  return axios.request({
    url: `${url}/goods/regions`,
    method: "get",
    params: data,
  })
}

export function getAreaSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/regions/select`,
    method: "get",
    params: data,
  })
}
export function getAreaById(id,params={}){
  return axios.request({
    url: `${url}/goods/region/${id}`,
    method: "get",
    params,
  })
}

export function deleteArea(id,data) {
  return axios.request({
    url: `${url}/goods/region/${id}`,
    method: "delete",
    params:data,
  })
}
export function addArea(data) {
  return axios.request({
    url: `${url}/goods/region`,
    method: "post",
    data: data,
  })
}
export function editArea(id, data) {
  return axios.request({
    url: `${url}/goods/region/${id}`,
    method: "put",
    data: data,
  })
}


export function getLevelList(data = {}) {
  return axios.request({
    url: `${url}/goods/levels`,
    method: "get",
    params: data,
  })
}

export function getLevelSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/levels/select`,
    method: "get",
    params: data,
  })
}
export function getLevelById(id,params={}){
  return axios.request({
    url: `${url}/goods/level/${id}`,
    method: "get",
    params,
  })
}

export function deleteLevel(id,data) {
  return axios.request({
    url: `${url}/goods/level/${id}`,
    method: "delete",
    params:data,
  })
}
export function addLevel(data) {
  return axios.request({
    url: `${url}/goods/level`,
    method: "post",
    data: data,
  })
}
export function editLevel(id, data) {
  return axios.request({
    url: `${url}/goods/level/${id}`,
    method: "put",
    data: data,
  })
}

export function getGrapeList(data = {}) {
  return axios.request({
    url: `${url}/goods/grapes`,
    method: "get",
    params: data,
  })
}

export function getGrapeSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/grapes/select`,
    method: "get",
    params: data,
  })
}
export function getGrapeById(id,params={}){
  return axios.request({
    url: `${url}/goods/grape/${id}`,
    method: "get",
    params,
  })
}

export function deleteGrape(id,data) {
  return axios.request({
    url: `${url}/goods/grape/${id}`,
    method: "delete",
    params:data,
  })
}
export function addGrape(data) {
  return axios.request({
    url: `${url}/goods/grape`,
    method: "post",
    data: data,
  })
}
export function editGrape(id, data) {
  return axios.request({
    url: `${url}/goods/grape/${id}`,
    method: "put",
    data: data,
  })
}

//查看箱子类型
export function getChests(data = {}) {
  return axios.request({
    url: `${url}/chests`,
    method: "get",
    params: data,
  })
}
//查看箱子类型
export function getChestsSelect(data = {}) {
  return axios.request({
    url: `${url}/chests/select`,
    method: "get",
    params: data,
  })
}
//查看箱子类型
export function getChestsById(id,data = {}) {
  return axios.request({
    url: `${url}/chest/${id}`,
    method: "get",
    params: data,
  })
}
export function addChests(data) {
  return axios.request({
    url: `${url}/chest`,
    method: "post",
    data: data,
  })
}
export function editChests(id, data) {
  return axios.request({
    url: `${url}/chest/${id}`,
    method: "put",
    data: data,
  })
}
export function deleteChests(id,data) {
  return axios.request({
    url: `${url}/chest/${id}`,
    method: "delete",
    params:data,
  })
}