<template>
    <div class="box">
                <el-button type="primary" plain @click="addGoods">添加商品 +</el-button>
            <el-table :data="tableData" style="width: 100%;margin-bottom:20px;">
                <el-table-column prop="goods_name" label="名称" width="240"></el-table-column>
                <el-table-column
                    prop="goods_net_weight"
                    label="重量（ml）"
                    width=""
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    align="center">
                    <template slot-scope="scope">
                        <span>瓶</span>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_quantity" label="数量">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.goods_quantity" type="number" style="width:220px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-button type="primary" plain @click="addPack">添加包装 +</el-button>
            <el-table :data="chestList" style="width: 100%;margin-bottom:20px;">
                <el-table-column prop="name" label="名称" width="240">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.name" style="width:220px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型" align="center" width="240">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.type" placeholder="请选择类型" clearable style="width:220px;">
                            <el-option
                                v-for="item in typeData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column  prop="unit" label="规格" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.unit" type="number" style="width:120px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="weight" label="重量（kg）"  align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.weight" type="number" style="width:120px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  prop="price" label="单价" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.price" type="number" style="width:120px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  prop="quantity" label="数量" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.quantity" type="number" style="width:120px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick2(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="width:100%;display: flex;justify-content: center;">
                <el-button type="primary" @click="onSubmit" style="">立即修改</el-button>
            </div>

        <el-dialog
        width="60%"
        title="商品"
        :visible.sync="dialogVisible"
        append-to-body>
            <div class="icon-dialog">
                <div class="icon-dialog-list" v-for="(value,key) in goodsList" :key="key" @click="chooseGoods(value)">
                    <el-image 
                    style="width: 100px; height: 100px"
                    :src="value.images[0]" 
                    fit="cover">
                    </el-image>
                    <p class="icon-name">{{value.name}}</p>
                    <p class="icon-name">{{value.price}}</p>
                </div>
            </div>
        </el-dialog>
    </div>

  </template>
  
  <script>
    let _this;
    import {getInStorageById,inStorage,editInStorage} from "@/api/stocks.js";
    import {getChestsSelect} from "@/api/goods.js";
    import {getList} from "@/api/goods.js";
    export default {
        props:['id'],
        data(){
            return{
                chestList:[],
                tableData:[],
                form:{},
                goodsList:[],
                dialogVisible:false,
                dialogPackVisible:false,
                packData:[],
                typeData:[],
                goods:[],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getInStorageById(this.id).then(res=>{
                    if (res.code === 200){
                        this.tableData = res.data.goods
                        this.chestList = res.data.chest
                        console.log(this.stockData)
                    }
                }),
                getChestsSelect(this.id).then(res=>{
                    if (res.code === 200){
                        this.typeData = res.data
                        console.log(this.typeData)
                    }
                }),
                getList().then(res=>{
                if(res.code === 200){
                    this.goodsList = res.data.data
                    console.log(this.goodsList)
                }
                })
            },
            addGoods(){
                this.dialogVisible = true
            },
            addPack(){
                let params = {
                    name:'',
                    unit:'',
                    type:'',
                    quantity:'',
                    weight:'',
                    price:''
                }
                this.chestList.push(params)
            },
            chooseGoods(e){
                console.log(e)
                this.dialogVisible=false
                let params = {
                    id:e.id,
                    name:e.name,
                    net_weight:e.net_weight,
                    unit:'瓶',
                    quantity:''
                }
                this.tableData.push(params)
            },
            handleClick(row){
                console.log(row)
                this.tableData = this.tableData.filter(item => item != row)
                console.log(this.tableData)
            },
            handleClick2(row){
                console.log(row)
                this.chestList = this.chestList.filter(item => item != row)
                console.log(this.chestList)
            },
            onSubmit(){
                let goodsList = []
                this.tableData.forEach(item=>{
                    goodsList.push({
                        name:item.goods_name,
                        id:item.goods_id,
                        quantity:item.goods_quantity,
                        net_weight:item.net_weight,
                        unit:'瓶',
                    })
                })
                this.form.goods_id = goodsList
                this.form.chest_id = this.chestList
                console.log(this.form)
                editInStorage(this.id,this.form).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: "修改成功",
                            type: "success"
                        });
                        setTimeout(() => {
                            this.$emit("success");
                            this.$emit("close");
                        }, 1000);
                    }
                })
            }
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  
  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 10px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
        .icon-name{
            margin-top:10px;
            font-size: 12px;
        }
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  </style>
  