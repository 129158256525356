<template>
  <section>
    <div class="container">
      <div class="setting-list" style="margin-bottom:20px;">
        <el-divider content-position="left">代理商折扣</el-divider>
        <div class="level-list" style="display:inline-flex; width:100%;align-items: center;margin-bottom:20px;" v-for="(value,key) in level" :key="key">
          <div class="level-list-title" style="flex-shrink: 0;">{{ value.name }}代理商折扣</div>
          <div class="types-price" style="margin-left: 20px;width:100%">
            <el-input style="width:100%" v-model="form[value.id]">
              <template slot="append">%</template>
            </el-input>
          </div>
        </div>
        <div style="width:100%;display:inline-flex;justify-content: center;">
          <el-button style="width:100px" @click="set">设置</el-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {getLevelListSelect,setLevelData,getLevelData} from '@/api/agents.js'
import {getTypesListSelect} from '@/api/goods.js'
let page_name = 'app_version'

export default {
  name: "App",
  data() {
    return {
      level:[],
      types:[],
      form:[]
    };
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    console.log(clientHeight)
    this.height = clientHeight-82-60-40-20-20-40
  },
  methods: {
    getTableData(){
      getLevelListSelect().then(res=>{
        if (res.code === 200){
          console.log(res.data.data)
          this.level = res.data
          getLevelData().then(r=>{
            if(r.code === 200){
              console.log(r.data)
              if(r.data.length > 0){
                res.data.forEach((value,key)=>{
                  console.log(key)
                  console.log(r.data[key])
                  this.form[value.id] = r.data[key] ? r.data[key] : 0
                })
              }else{
                res.data.forEach((value,key)=>{
                  this.form[value.id] = 30
                })
              }
            }
            this.$forceUpdate()
          })
        }
      })
      
    },
    set(){
      console.log(this.form)
      let form = []
      this.form.forEach((value,key)=>{
        if(key){
          form.push(parseInt(value))
        }
      })
      form = JSON.stringify(form)
      console.log(form)
      setLevelData({form}).then(res=>{
        if(res.code === 200){
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.getTableData()
        }
      })
    }
    
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 40px 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    
  }
}

.control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

  .oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
