<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>入库单列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入主题" clearable></el-input>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.serial" placeholder="请输入单号" clearable></el-input>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleIn" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">入库</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="ID" prop="id" align="center" width="120"></el-table-column>
          <el-table-column label="入库主题" prop="name" align="center" width="120"></el-table-column>
          <el-table-column label="入库日期" prop="created_at" align="center">

          </el-table-column>
          <el-table-column label="入库仓库" prop="warehouse.name" align="center"></el-table-column>
          <el-table-column label="产品详细" prop="goods.goods_name" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="top-start"
                title="详情"
                width="550"
                trigger="click"> 
                <el-table :data="scope.row.goods">
                  <el-table-column width="160" property="goods_name" label="产品名称"></el-table-column>
                  <el-table-column width="120" property="goods_net_weight" label="重量（ml）" align="center"></el-table-column>
                  <el-table-column width="70" property="unit" label="单位">
                    <template slot-scope="slot">
                      <span>瓶</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" property="goods_quantity" label="数量"></el-table-column>
                </el-table>
                <el-table :data="scope.row.chest">
                  <el-table-column width="160" property="name" label="包装名称"></el-table-column>
                  <el-table-column width="70" property="unit" label="规格"></el-table-column>
                  <el-table-column width="120" property="weight" label="重量（kg）" align="center"></el-table-column>
                  <el-table-column width="70" property="quantity" label="数量" align="center"></el-table-column>
                  <el-table-column width="100" property="price" label="单价"></el-table-column>
                </el-table>
                <el-button slot="reference">查看明细</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="comment" align="center">
            <template slot-scope="scope">
              <el-popover
                placement="top-start"
                title="详情"
                width="420"
                trigger="click"> 
                <div style="white-space: pre-line;">{{ scope.row.comment }}</div>
                <el-button slot="reference">查看备注</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="总成本" prop="total_price" align="center"></el-table-column>
          <el-table-column label="操作员" prop="admin_id" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.admin_id>0">{{ scope.row.admin.nickname }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="70" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-around;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==1&&scope.row.status !==3)" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="确认入库" placement="top-start">
                    <i class="el-icon-document-add" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" v-if="(scope.row.status !==1&&scope.row.status !==3)" @click="editDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改明细" placement="top-start">
                  <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" v-if="(scope.row.status ==1)" @click="cancelIn(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="撤销入库" placement="top-start">
                    <i class="el-icon-document-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link v-if="(scope.row.status ==3)">
                  <span>已撤销</span>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>


    <el-dialog title="入库单" v-if="innerGoodsVisible" :visible.sync="innerGoodsVisible" width="60%">
      <stock-in :id="stockInID"  @success="handleInSuccess"></stock-in>
    </el-dialog>
    <!-- 入库信息 -->
    <el-dialog title="明细更改" v-if="innerVisible" :visible.sync="innerVisible" width="60%">
      <goods-detail :id="goodsDetailID"  @success="handleDrawerSuccess"></goods-detail>
    </el-dialog>
  </section>
</template>

<script>
  import { getInStorage,setPutIn,cancelById} from "@/api/stocks.js";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import stockIn from "@/views/components/stockIn.vue";
  import stockOut from "@/views/components/stockOut.vue";
  import goodsDetail from "@/views/components/goodsDetail.vue";

  let page_name = 'store_list'
  export default {
    name: "App",
    components:{addComp,editComp,stockIn,stockOut,goodsDetail},
    data() {
      return {
        percent:'',
        admin_permission:[],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          name:'',
          serial:'',
        },
        innerVisible:false,
        innerGoodsVisible:false,
        stockInID:'',
        groupList:[],
        groupID:'',
        table:[],
        service: [],
        editID: '',
        total: 0,
        goodsDetailID:'',
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        },
        gridData:[]
      };
    },

    methods: {
      getTableData() {
        getInStorage(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      
      // 入库
      handleIn() {
        this.innerGoodsVisible = true;
      },
      
      // 添加/修改成功
      handleInSuccess() {
        this.getTableData()
        this.innerGoodsVisible = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },
      cancelIn(id){
        console.log(id)
        this.$confirm('确认撤销此入库单吗？')
          .then(_ => {
            cancelById(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '已撤销',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      // 删除
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteWarehouse(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogVisible=false
          })
          .catch((_) => {});
      },
      editDetail(id){
        this.innerVisible=true
        this.goodsDetailID = id
      },
      editTable(id){
        console.log(id)

        this.$confirm('确认入库吗？')
          .then(_ => {
            setPutIn(id).then((res) => {
              if (res.code === 200) {
                this.getTableData()
                this.$message({
                  message: "修改成功",
                  type: "success"
                });
                setTimeout(() => {
                  this.$emit("success");
                }, 1000);
              }
            });
          })
          .catch(_ => {});
        // this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        this.innerVisible = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
