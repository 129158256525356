<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>仓库列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入仓库名称" clearable></el-input>
              <el-select v-model="params.province_id" placeholder="请选择省份" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="areaClick()">
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">添加仓库</el-button>
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleIn" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">入库</el-button> -->
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleOut" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">出库</el-button> -->
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="仓库名称" prop="name" align="center" width="120"></el-table-column>
          <el-table-column label="仓库省份" prop="province_name" align="center"></el-table-column>
          <el-table-column label="仓库城市" prop="city_name" align="center"></el-table-column>
          <el-table-column label="仓库区域" prop="area_name" align="center"></el-table-column>
          <el-table-column label="地址" prop="address" align="center" width="200"></el-table-column>
          <el-table-column label="仓库类型" prop="type" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.type===0">实仓</span>
              <span v-if="scope.row.type===1">云仓</span>
            </template>
          </el-table-column>
          <el-table-column label="联系方式" prop="phone" align="center" width="120"></el-table-column>

          <el-table-column label="操作" width="80" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-around;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                  <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                  <i class="el-icon-delete" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>


    <el-drawer
      title="更改仓库信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加仓库"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    
    <!-- 入库信息 -->
    <el-dialog title="入库单" v-if="innerVisible" :visible.sync="innerVisible" width="60%">
      <stock-in :id="stockInID"  @success="handleDrawerSuccess"></stock-in>
    </el-dialog>
    <!-- 出库信息 -->
    <el-dialog title="出库单" v-if="outerVisible" :visible.sync="outerVisible" width="60%">
      <stock-out :id="stockOutID"></stock-out>
    </el-dialog>
  </section>
</template>

<script>
  import { getWarehousesList, deleteWarehouse} from "@/api/stocks.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import stockIn from "@/views/components/stockIn.vue";
  import stockOut from "@/views/components/stockOut.vue";

  let page_name = 'store_list'
  export default {
    name: "App",
    components:{addComp,editComp,stockIn,stockOut},
    data() {
      return {
        percent:'',
        admin_permission:[],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          name:'',
          province_id:'',
          city_id:'',
          area_id:'',
        },
        groupList:[],
        groupID:'',
        table:[],
        service: [],
        editID: '',
        total: 0,
        stockInID:'',
        stockOutID:'',
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getWarehousesList(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      searchType(){
        // getTypeList().then(res=>{
        //   if(res.code === 200){
        //     this.storeTypeOptions = res.data
        //     console.log(this.storeTypeOptions)
        //   }
        // })
      },
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      // 删除
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteWarehouse(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 入库
      handleIn() {
        this.innerVisible = true;
      },
      // 出库
      handleOut() {
        this.outerVisible = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogVisible=false
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        this.innerVisible = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      this.searchType();
      
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
