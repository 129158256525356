<template>
    <div class="box">
            <div>对应订单：<span style="color:#409eff;">{{form.theme}}</span></div>
            <div style="width:80%;display:flex;justify-content: flex-start;margin-top:20px;">
                <div>仓库：<span style="color:#409eff;">{{form.stock}}</span></div>
                <div style="margin-left:50px;">出库日期：<span style="color:#409eff;">{{form.date}}</span></div>
            </div>
            <div style="margin-top:20px;">出库明细：</div>
            <el-table :data="tableData" show-summary :summary-method="getSummaries" max-height="300" style="width: 90%;margin-bottom:20px;margin-top:10px;margin-left:3%;border:1px solid #ccc;">
                <el-table-column
                    prop="name"
                    label="产品名称"
                    width="240">
                </el-table-column>
                <el-table-column
                    prop="net_weight"
                    label="重量（kg）"
                    width=""
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="数量">
                </el-table-column>
                <el-table-column
                    prop="stock"
                    label="本库存/总库存">
                </el-table-column>
            </el-table>
            <div>出库备注：<span style="color:#409eff;">{{form.tips}}</span></div>
            <div style="width:80%;display:flex;justify-content: flex-start;margin-top:20px;">
                <div>出库经办人：<span style="color:#409eff;">{{form.user}}</span></div>
                <div style="margin-left:50px;">出库日期：
                    <el-radio-group v-model="form.status">
                        <el-radio :label="0">未出库</el-radio>
                        <el-radio :label="1">已出库</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <!-- <div @click="addGoods">
                添加商品 <span style="font-size:20px;margin-top:15px;">+</span>
            </div> -->
    </div>

  </template>
  
  <script>
    let _this;
    import {getStocksListSelect} from "@/api/stocks.js";
    import {getList} from "@/api/goods.js";
    export default {
        props:['id'],
        data(){
            return{
                form:{
                    status:0,
                    theme:'自动主题—葡萄酒干红',
                    date:'2020-03-01',
                    stock:'宁波仓库',
                    user:'林钟权',
                    tips:"萨达咖啡机哦怕发送大附件哦怕的评价佛i阿飞骄傲平均得分啊萨达倒萨怕"
                },
                goodsList:[],
                tableData:[
                    {
                        name:'红葡萄酒',
                        net_weight:'150',
                        unit:'瓶',
                        num:'20',
                        stock:'1150/5230',
                    },
                    {
                        name:'白葡萄酒',
                        net_weight:'64',
                        unit:'瓶',
                        num:'15',
                        stock:'1150/5230',
                    }
                ],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getList().then(res=>{
                if(res.code === 200){
                    this.goodsList = res.data.data
                    console.log(this.goodsList)
                }
                })
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index === 2) {
                    sums[index] = '';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += '';
                } else {
                    sums[index] = 'N/A';
                }
                });

                return sums;
            }
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  
  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 10px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
        .icon-name{
            margin-top:10px;
            font-size: 12px;
        }
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  </style>
  