import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/agents`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/agents/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/agent`,
    method: "post",
    data: data,
  })
}

export function appointData(id, data) {
  return axios.request({
    url: `${url}/agent/appointment/${id}`,
    method: "put",
    data: data,
  })
}

export function failData(id, data) {
  return axios.request({
    url: `${url}/agent/fail/${id}`,
    method: "put",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/agent/${id}`,
    method: "put",
    data: data,
  })
}

export function getLevelList(data = {}) {
  return axios.request({
    url: `${url}/agents/levels`,
    method: "get",
    params: data,
  })
}

export function getLevelListSelect(data = {}) {
  return axios.request({
    url: `${url}/agents/levels/select`,
    method: "get",
    params: data,
  })
}
export function getLevelDataById(id,params={}){
  return axios.request({
    url: `${url}/agents/level/${id}`,
    method: "get",
    params,
  })
}

export function deleteLevelData(id,data) {
  return axios.request({
    url: `${url}/agents/level/${id}`,
    method: "delete",
    params:data,
  })
}
export function addLevelData(data) {
  return axios.request({
    url: `${url}/agents/level`,
    method: "post",
    data: data,
  })
}
export function getLevelData(params) {
  return axios.request({
    url: `${url}/agents/levels/setting`,
    method: "get",
    params,
  })
}
export function setLevelData(data) {
  return axios.request({
    url: `${url}/agents/levels/setting`,
    method: "post",
    data: data,
  })
}
export function editLevelData(id, data) {
  return axios.request({
    url: `${url}/agents/level/${id}`,
    method: "put",
    data: data,
  })
}

