<template>
  <section style="padding:0 5%; height: 100%;">
    <el-form ref="form" label-position="top" status-icon :model="form" :rules="rules" label-width="100px"
             style="width:100%;height: calc(100% - 20px);overflow:auto">
      <el-form-item label="名称" prop="name" required>
        <el-input placeholder="请输入名称" v-model="form.name">
        </el-input>
      </el-form-item>

      <el-form-item label="上传国家图片">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" path="goods" :num="1" :images="imageUrl"></upload-qiniu>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addAssistant('form')" :plain='true'>立即添加</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>

<script>
  let _this;
  import {addCountryData} from '@/api/goods.js';
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
 
  export default {
    components: {
      UploadQiniu
    },
    data() {
      return {
        form: {
          name: '',
          image:"",
        },
        imageUrl:[],

        rules: {
          name: [
            {required: true, message: "请输入商品产地名称", trigger: "blur"},
          ]
        },
      };
    },
    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },

    methods: {
      init(){
      },
      // 数据初始化
      close() {
        _this.$emit('close');
      },

      agentChange(e){
        if(e && !this.form.idcard){
          this.$message.error("请先完成认证")
          this.form.is_agent = 0;
        }
      },
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.image = e[0];
      },
      handleLicenseRemove(e) {
        this.form.image = "";
      },

      //  添加
      addAssistant(form) {

        _this.$refs["form"].validate(valid => {
          if (valid) {
            console.log(_this.form)
            addCountryData(_this.form)
              .then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "添加成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
              .catch(error => {
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      handleDistrictsChoose(e) {
        
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-drawer {
    overflow: hidden;
  }

  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
