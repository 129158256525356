<template>
  <section style="padding:0 5%;">
    <el-form ref="form" label-position="top" status-icon :model="form" :rules="rules" label-width="100px"
             style="width:100%;overflow:auto">
             <el-form-item label="名称" prop="name" required>
        <el-input placeholder="请输入名称" v-model="form.name">
        </el-input>
      </el-form-item>

      <el-form-item label="商品类别" prop="type_id" required>
        <el-select v-model="form.type_id" clearable filterable placeholder="请选择商品类别" style="width:100%">
          <el-option
            v-for="(value,key) in typeOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="原产国" prop="country" required>
        <el-select v-model="form.country" clearable filterable placeholder="请选择原产国" style="width:100%">
          <el-option
            v-for="(value,key) in countryOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品产区" prop="regions_id">
        <el-select v-model="form.regions_id" clearable filterable placeholder="请选择商品产区" style="width:100%">
          <el-option
            v-for="(value,key) in regionOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="葡萄种类" prop="grape_id">
        <el-select v-model="form.grape_id" clearable filterable multiple placeholder="请选择葡萄种类" style="width:100%">
          <el-option
            v-for="(value,key) in grapeOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品级别" prop="level_id">
        <el-select v-model="form.level_id" clearable filterable placeholder="请选择商品级别" style="width:100%">
          <el-option
            v-for="(value,key) in levelOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品标签" prop="goods_tag">
        <el-select v-model="form.goods_tag" clearable filterable placeholder="请选择商品标签" multiple  style="width:100%">
          <el-option
            v-for="(value,key) in tagOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="售价" prop="price" required>
        <el-input placeholder="请输入售价" v-model="form.price">
        </el-input>
      </el-form-item> -->
      <el-form-item label="采购价" prop="purchase_price" required>
        <el-input placeholder="请输入采购价" v-model="form.purchase_price">
        </el-input>
      </el-form-item>
      <el-form-item label="扫码价" prop="scan_price" required>
        <el-input placeholder="请输入扫码价" v-model="form.scan_price">
        </el-input>
      </el-form-item>
      <el-form-item label="年份" prop="years" required>
        <el-input placeholder="请输入年份" v-model="form.years">
        </el-input>
      </el-form-item>
      
      <!-- <el-form-item label="售卖单位" prop="unit" required>
        <el-radio-group v-model="form.unit">
          <el-radio-button :label="0">瓶</el-radio-button>
          <el-radio-button :label="1">箱</el-radio-button>
        </el-radio-group>
      </el-form-item> -->

      <el-form-item label="净重" prop="net_weight" required>
        <el-input placeholder="请输入净重" v-model="form.net_weight">
          <template slot="append">ml</template>
        </el-input>
      </el-form-item>
      <el-form-item label="重量" prop="bottle_weight" required>
        <el-input placeholder="重量" v-model="form.bottle_weight">
          <template slot="append">g</template>
        </el-input>
      </el-form-item>

      <!-- <el-form-item label="存量" prop="purchase_price" required>
        <el-input placeholder="请输入存储量" v-model="form.stock">
        </el-input>
      </el-form-item> -->

      <el-form-item label="上传商品照片">
        <upload-qiniu @success="handleLicenseSuccess" @remove="handleLicenseRemove" :multiple="true" path="goods" :num="9" :images="form.images"></upload-qiniu>
      </el-form-item>
      <el-form-item label="详情" prop="info">
        <quill-editor v-model="form.info" ref="myQuillEditor" style="" :options="editorOption">
        </quill-editor> 
         <!-- 图片上传组件辅助-->
        <upload-qiniu v-if="editorIsClickImage"  class="avatar-uploader" ref="avatarUploader" @success="handlePicSuccess" path="goods" :multiple="false" :num="1" style=""></upload-qiniu>
        <!-- <el-upload :action="uploadUrl" name="img" :show-file-list="false"
                  :on-success="uploadSuccess" :before-upload="beforeUpload">
        </el-upload> -->
      </el-form-item>

      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="addAssistant('form')" :plain='true'>立即修改</el-button>
      </el-form-item>

    </el-form>
  </section>
</template>

<script>
  let _this;
  import {editData,getSelect,getDataById,getTypesListSelect,getTagsListSelect,getCountryListSelect,getAreaSelect,getGrapeSelect,getLevelSelect} from '@/api/goods.js';
  import {getListSelect} from '@/api/store.js';
  import {
    quillEditor
  } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  export default {
    components: {
      quillEditor,
      UploadQiniu
    },
    props: {
      id: Number
    },
    data() {
      return {
        content: null,
        typeOptions:[],
        tagOptions:[],
        storeOptions:[],
        countryOptions:[],
        regionOptions:[],
        grapeOptions:[],
        levelOptions:[],
        editorIsClickImage:false,
        editorOption: {
          modules: {
            toolbar: {
              container: [
                ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                ['blockquote', 'code-block'], // 引用  代码块
                [{ header: 1 }, { header: 2 }], // 1、2 级标题
                [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                [{ indent: '-1' }, { indent: '+1' }], // 缩进
                [{ direction: 'rtl' }], // 文本方向
                [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                // [{ font: ['songti'] }], // 字体种类
                [{ align: [] }], // 对齐方式
                ['clean'], // 清除文本格式
                ['image', 'video'] // 链接、图片、视频
              ],
              handlers: {
                'image': function (value) {
                  if (value) { // value === true
                    // this.$refs.avatarUploader.click()
                    _this.editorIsClickImage = true
                    _this.$nextTick(()=>{
                      _this.$refs.avatarUploader.$refs.uploadImage.$children[1].$el.lastChild.click()
                    })
                  } else {
                    this.quill.format('image', false)
                  }
                }
              }
            }
          },
          placeholder: '请输入正文'
        },
        form: {
          name:'',
          purchase_price:'',
          goods_tag:'',
          info:'',
          images:[],
          country:'',
          regions_id:'',
          grape_id:'',
          level_id:'',
          net_weight:0,
          bottle_weight:0,
          years:''
        },
        value: '',
        options: [{
          value: 0,
          label: '整包'
        }, {
          value: 1,
          label: 'wgt包'
        }],

        rules: {
          name: [
            {required: true, message: "请输入名称", trigger: "blur"},
          ],
          price: [
            {required: true, message: "请输入售价", trigger: "blur"},
          ],
          purchase_price: [
            {required: true, message: "请输入采购价", trigger: "blur"},
          ],
          goods_type: [
            {required: true, message: "请选择类别", trigger: "change"},
          ],
          goods_country: [
            {required: true, message: "请选择原产国", trigger: "change"},
          ]
        },
      };
    },
    computed: {},

    mounted() {
      _this = this;
      _this.init();
    },
    watch:{
      content(newVal, oldVal) {
        //this.$emit('input', newVal);
        console.log(newVal)
        this.form.info = newVal
      }
    },
    methods: {
      init(){
        getDataById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            this.keywordResult = res.data.address
            console.log(this.form)
          }
        })
        getListSelect(this.params).then(res=>{
          if (res.code === 200){
            // console.log(res.data)
            this.storeOptions = res.data
          }
        })
        getTypesListSelect(this.params).then(res=>{
          if (res.code === 200){
            // console.log(res.data)
            this.typeOptions = res.data
          }
        })
        getTagsListSelect(this.params).then(res=>{
          if (res.code === 200){
            // console.log(res.data)
            this.tagOptions = res.data
          }
        })
        getCountryListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.countryOptions = res.data
          }
        })
        getAreaSelect(this.params).then(res=>{
          if(res.code === 200){
            this.regionOptions = res.data
          }
        })
        getGrapeSelect(this.params).then(res=>{
          if(res.code === 200){
            this.grapeOptions = res.data
          }
        })
        getLevelSelect(this.params).then(res=>{
          if(res.code === 200){
            this.levelOptions = res.data
          }
        })
      },
      // 数据初始化
      close() {
        _this.$emit('close');
      },

      // 上传营业执照删除、缓存、预览
      handleLicenseSuccess(e) {
        console.log(e)
        this.form.images = e;
      },
      handleLicenseRemove(e) {
        this.form.images = e;
      },
      agentChange(e){
        if(e && !this.form.idcard){
          this.$message.error("请先完成认证")
          this.form.is_agent = 0;
        }
      },

      // 上传图片删除、缓存、预览
      handlePicSuccess(res) {
        console.log(res)
        let quill = this.$refs.myQuillEditor.quill
        // 如果上传成功
        if (res) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片，res为服务器返回的图片链接地址
          quill.insertEmbed(length, 'image', res[0])
          quill.setSelection(length + 1)
          this.editorIsClickImage=false
          // 调整光标到最后
        } else {
          // 提示信息，需引入Message
          this.$message.error('图片插入失败！')
        }
      },
      beforeUpload(file) {
      },
      //  修改
      addAssistant(form) {
        _this.$refs["form"].validate(valid => {
          if (valid) {
            editData(_this.id,_this.form)
              .then((res) => {
                if (res.code === 200) {
                  _this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  setTimeout(() => {
                    _this.$emit("success");
                  }, 1000);
                }
              })
              .catch(error => {
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      handleDistrictsChoose(e) {
        _this.form.province_id = e.province.code
        _this.form.city_id = e.city.code
        _this.form.area_id = e.area.code
      },
    }
  };
</script>
<style lang="scss" scoped>
  .el-drawer {
    overflow: hidden;
  }
  .avatar-uploader{
    display:none;
  }
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
