<template>
    <div class="container">
        <div class="top">
            <div class="user">
                <div class="name">{{agentData.name}}</div>
                <div class="parent">数据所有人：{{agentData.parent_id}}</div>
            </div>
            <div class="lists">
                <div class="list">
                    <div class="title blue">合同单总额</div>
                    <div class="price blue-word"><span>￥</span>45</div>
                </div>
                <div class="list">
                    <div class="title red">未回款金额</div>
                    <div class="price red-word"><span>￥</span>45</div>
                </div>
                <div class="list">
                    <div class="title green">已回款金额</div>
                    <div class="price green-word"><span>￥</span>0</div>
                </div>
                <div class="list">
                    <div class="title green2">已开票金额</div>
                    <div class="price green2-word"><span>￥</span>0</div>
                </div>
            </div>
        </div>
        <div class="center">
            <div class="left">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="color:dodgerblue;">公司基本信息</span>
                    </div>
                    <div class="company-list">
                        <div style="margin-bottom:10px;">
                            <span style="color:gray;">种类：</span>
                             样品客户
                        </div>
                        <div style="margin-bottom:10px;">
                            <span style="color:gray;">手机：</span>
                             18752635874
                        </div>
                        <div style="margin-bottom:10px;">
                            <span style="color:gray;">客户名称：</span>
                             {{agentData.name}}
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="color:dodgerblue;">上级客户</span>
                    </div>
                    <div class="company-list">
                        <div style="margin-bottom:10px;">
                            <span style="color:gray;">手机：</span>
                             18752635874
                        </div>
                        <div style="margin-bottom:10px;">
                            <span style="color:gray;">客户名称：</span>
                             {{agentData.name}}
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="color:dodgerblue;">下级客户列表</span>
                    </div>
                    <el-table>

                    </el-table>
                </el-card>
            </div>
            <div class="right">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span style="color:dodgerblue;">订单</span>
                    </div>
                </el-card>
            </div>
        </div>
    </div>

  </template>
  
  <script>
    let _this;
    import {getDataById} from "@/api/agents.js";
    export default {
        props:['id'],
        data(){
            return{
                agentData:[],
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getDataById(this.id).then(res=>{
                    if (res.code === 200){
                        this.agentData = res.data
                        console.log(this.agentData)
                    }
                })
            },
        }
    };
  </script>
  <style lang="scss" scoped>
 .container{
    display: block !important;
    .top{
        display: flex;
        justify-content: space-between;
        width:100%;
        .user{
            .name{
                font-size:24px;
            }
            .parent{
                font-size:14px;
                margin-top:10px;
            }
        }
        .lists{
            width:40%;
            display: flex;
            justify-content: space-around;
            .list{
                width:100px;
                text-align: center;
                .title{
                    border-radius: 10px;
                }
                .price{
                    font-size:28px;
                    span{
                        font-size:16px;
                    }
                }
            }
            .blue{
                background-color:dodgerblue;
                color:#fff;
            }
            .blue-word{
                color:dodgerblue;
            }
            .red{
                background-color:crimson;
                color:#fff;
            }
            .red-word{
                color:crimson;
            }
            .green{
                background-color:rgb(24, 122, 24);
                color:#fff;
            }
            .green-word{
                color:rgb(24, 122, 24);
            }
            .green2{
                background-color:rgb(5, 182, 182);
                color:#fff;
            }
            .green2-word{
                color:rgb(5, 182, 182);
            }
        }
    }
    .center{
        display: flex;
        justify-content:space-between;
        margin-top:20px;
        .left{
            width:30%;
            .box-card{
                margin-bottom:20px;
            }
        }
        .right{
            width:65%;
        }
    }
 }
  </style>
  