<template>
    <div class="box">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px" :inline="true" style="padding:0 30px;">
            <el-form-item label="入库主题：" style="width:45%;" required>
                <el-input v-model="form.name" style="width:220px;"></el-input>
            </el-form-item>
            <el-form-item label="仓库：" style="width:45%;" required>
                <el-select v-model="form.warehouse_id" placeholder="请选择仓库" clearable style="width:220px;">
                    <el-option
                        v-for="item in stockData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="入库状态：" style="width:45%;">
                <el-radio-group v-model="form.status">
                    <el-radio :label="0">未入库</el-radio>
                    <!-- <el-radio :label="1">已入库</el-radio> -->
                </el-radio-group>
            </el-form-item>
            <el-form-item  style="width:100%;">
                <el-button type="primary" plain @click="addGoods">添加商品 +</el-button>
            </el-form-item>
            <!-- <div @click="addGoods">
                添加商品 <span style="font-size:20px;margin-top:15px;">+</span>
            </div> -->
            <el-table :data="tableData" style="width: 100%;margin-bottom:20px;">
                <el-table-column prop="name" label="名称" width="240"></el-table-column>
                <el-table-column
                    prop="net_weight"
                    label="重量（ml）"
                    width=""
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位"
                    align="center">
                </el-table-column>
                <el-table-column prop="quantity" label="数量">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.quantity" type="number" @input="numChange($event,scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-form-item  style="width:100%;">
                <el-button type="primary" plain @click="addPack">添加包装 +</el-button>
            </el-form-item>
            <el-table :data="chestList" style="width: 100%;margin-bottom:20px;">
                <el-table-column prop="name" label="名称" width="120">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                            <p>{{ scope.row.name }}</p>
                            <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                                <span>{{scope.row.name}}</span>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型" align="center" width="200">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.type" placeholder="请选择类型" clearable style="width:180px;" @change="typeChange($event,scope.row)">
                            <el-option
                                v-for="item in typeData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column  prop="unit" label="规格" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.unit" type="number" @input="unitChange($event,scope.row)"></el-input>
                    </template>
                </el-table-column>
                <el-table-column prop="weight" label="重量（kg）"  align="center" width="100">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.weight" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  prop="price" label="单价" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.price" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  prop="quantity" label="数量" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.quantity" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick2(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-form-item label="入库备注：" style="width:100%;" required>
                <el-input v-model="form.comment" type="textarea" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item style="width:100%;">
                <el-button type="primary" @click="onSubmit" style="margin-left:100%;">立即创建</el-button>
            </el-form-item>
        </el-form>

        <el-dialog
        width="800px"
        title="商品"
        :visible.sync="dialogVisible"
        append-to-body>
            <div class="icon-dialog">
                <div class="icon-dialog-lists">
                    <div class="icon-dialog-list" v-for="(value,key) in goodsList" :key="key" @click="chooseGoods(value)">
                        <el-image 
                        style="width: 100px; height: 100px"
                        :src="value.images[0]" 
                        fit="cover"
                        >
                        </el-image>
                        <p class="icon-name">{{value.name}}</p>
                        <p class="icon-name">{{value.price}}</p>
                        <i class="iconfont icon-weigouxuan" v-if="value.chooseShow"></i>
                        <i class="iconfont icon-yigouxuan" v-if="!value.chooseShow"></i>
                    </div>
                </div>
                
                <el-button type="primary" class="confirm" @click="confirmGoods()">确定</el-button>
            </div>
        </el-dialog>
    </div>

  </template>
  
  <script>
    let _this;
    import {getStocksListSelect,inStorage} from "@/api/stocks.js";
    import {getChestsSelect} from "@/api/goods.js";
    import {getList,getListSelect} from "@/api/goods.js";
    export default {
        props:['id'],
        data(){
            return{
                form:{
                    status:0,
                    name:'',
                    warehouse_id:'',
                    comment:''
                },
                chestList:[],
                tableData:[],
                rules: {
                    name: [
                        {required: true, message: "请输入入库主题", trigger: "blur"},
                    ],
                    stock_id: [
                        {required: true, message: "请选择仓库", trigger: "change"},
                    ],
                    stock_id: [
                        {required: true, message: "请选择仓库", trigger: "change"},
                    ],
                },
                params:[],
                chestParams:[],
                goodsList:[],
                dialogVisible:false,
                dialogPackVisible:false,
                packData:[],
                typeData:[],
                goods:[],
                stockData:[]
            }
        },
        mounted(){
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getStocksListSelect(this.id).then(res=>{
                    if (res.code === 200){
                        this.stockData = res.data
                        // console.log(this.stockData)
                    }
                }),
                getChestsSelect(this.id).then(res=>{
                    if (res.code === 200){
                        this.typeData = res.data
                        // console.log(this.typeData)
                    }
                }),
                getListSelect().then(res=>{
                    if(res.code === 200){
                        res.data.forEach(item=>{
                            item.chooseShow = true
                        })
                        this.goodsList = res.data
                        // console.log(this.goodsList)
                    }
                })
            },
            addGoods(){
                this.dialogVisible = true
            },
            addPack(){
                let params = {
                    name:'',
                    unit:'',
                    type:'',
                    quantity:'',
                    weight:'',
                    price:''
                }
                this.chestList.push(params)
            },
            // chooseGoods(e){
            //     // console.log(e)
            //     this.dialogVisible=false
            //     let params = {
            //         id:e.id,
            //         name:e.name,
            //         net_weight:e.net_weight,
            //         unit:'瓶',
            //         quantity:''
            //     }
            //     let chestParams = {
            //         name:e.name+'原箱',
            //         unit:6,
            //         type:1,
            //         quantity:'',
            //         weight:0,
            //         price:0
            //     }
            //     this.tableData.push(params)
            //     this.chestList.push(chestParams)
            //     console.log(this.chestList)
            // },
            chooseGoods(e){
                // console.log(e)
                
                e.chooseShow=!e.chooseShow
                
                let params = {
                    id:e.id,
                    name:e.name,
                    net_weight:e.net_weight,
                    unit:'瓶',
                    quantity:''
                }
                let chestParams = {
                    name:e.name+'原箱',
                    unit:6,
                    type:1,
                    quantity:'',
                    weight:0,
                    price:0
                }
                if(e.chooseShow == false){
                    this.tableData.push(params)
                    this.chestList.push(chestParams)
                }else{
                    console.log(params)
                    this.tableData = this.tableData.filter((item) =>{
                        return item.id != params.id
                    })
                    this.chestList = this.chestList.filter((item) =>{
                        return item.name != chestParams.name
                    })
                    console.log(this.tableData)
                    console.log(this.chestList)
                }
            },
            confirmGoods(){
                this.dialogVisible = false
            },
            typeChange(e,row){
                console.log(e)
                console.log(row)
                this.typeData.forEach(item=>{
                    if(item.id === e){
                        row.name = item.name
                    }
                })
            },
            numChange(e,row){
                // console.log(e)
                // console.log(row)
                let unit = ''
                this.chestList.forEach(item=>{
                    let nameList = item.name.split('原')
                    let name = nameList[0]
                    // console.log(name)
                    if(name == row.name){
                        unit = item.unit
                        item.quantity = parseInt(e/unit)
                    }
                })
                // this.chestList.forEach(item=>{
                //     item.quantity = parseInt(e/unit)
                // })

            },
            unitChange(e,row){
                console.log(e)
                console.log(row.name)
                let nameList = row.name.split('原')
                let name = nameList[0]
                let total = ''
                this.tableData.forEach(item=>{
                    if(item.name == name){
                        total = item.quantity
                    }
                })
                console.log(total)
                this.chestList.forEach(item=>{
                    if(item.name == row.name){
                        item.quantity = parseInt(total/e)
                    }
                })

            },
            handleClick(row){
                console.log(row)
                this.tableData = this.tableData.filter(item => item != row)
                console.log(this.tableData)
            },
            handleClick2(row){
                console.log(row)
                this.chestList = this.chestList.filter(item => item != row)
                console.log(this.chestList)
            },
            onSubmit(){
                console.log(this.tableData)
                this.form.goods = this.tableData
                this.form.chest = this.chestList
                console.log(this.form)
                inStorage(this.form).then(res => {
                    if (res.code === 200) {
                        
                        this.$message({
                            message: "添加成功",
                            type: "success"
                        });
                        setTimeout(() => {
                            this.$emit("success");
                            this.$emit("close");
                        }, 1000);
                    }
                })
            }
        }
    };
  </script>
  <style lang="scss" scoped>
  .title{
    
  }
  
  .icon-dialog{
    width: 780px;
    height:650px;
    .icon-dialog-lists{
        width: 100%;
        height:600px;
        display:flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow-y:scroll;
        .icon-dialog-list{
            box-sizing: border-box;
            padding: 10px 10px;
            width: 16.66%;
            font-size: 32px;
            text-align: center;
            border:1px solid #dbdbdb;
            position: relative;
            .icon-name{
                margin-top:10px;
                font-size: 12px;
            }
            .icon-weigouxuan{
                position: absolute;
                bottom:5px;
                right:5px;
            }
            .icon-yigouxuan{
                position: absolute;
                bottom:5px;
                right:5px;
                color:#409eff;
            }
        }
        .icon-dialog-list:hover{
            color:#409eff;
            border-color:#409eff;
        }
    }
    .confirm{
        width:80px;
        margin-left:340px;
        margin-top:20px;
    }
    
  }
  </style>
  