import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``


export function getCompaniesList(data = {}) {
  return axios.request({
    url: `${url}/logistics/companies`,
    method: "get",
    params: data,
  })
}

export function getCompaniesListSelect(data = {}) {
  return axios.request({
    url: `${url}/logistics/companies/select`,
    method: "get",
    params: data,
  })
}
export function getCompanyById(id,params={}){
  return axios.request({
    url: `${url}/logistics/company/${id}`,
    method: "get",
    params,
  })
}

export function deleteCompany(id,data) {
  return axios.request({
    url: `${url}/logistics/company/${id}`,
    method: "delete",
    params:data,
  })
}
export function addCompany(data) {
  return axios.request({
    url: `${url}/logistics/company`,
    method: "post",
    data: data,
  })
}
export function editCompany(id, data) {
  return axios.request({
    url: `${url}/logistics/company/${id}`,
    method: "put",
    data: data,
  })
}


export function getPricesList(data = {}) {
  return axios.request({
    url: `${url}/logistics/prices`,
    method: "get",
    params: data,
  })
}

export function getPricesListSelect(data = {}) {
  return axios.request({
    url: `${url}/logistics/prices/select`,
    method: "get",
    params: data,
  })
}
export function getPriceById(id,params={}){
  return axios.request({
    url: `${url}/logistics/price/${id}`,
    method: "get",
    params,
  })
}

export function deletePrice(id,data) {
  return axios.request({
    url: `${url}/logistics/price/${id}`,
    method: "delete",
    params:data,
  })
}
export function addPrice(data) {
  return axios.request({
    url: `${url}/logistics/price`,
    method: "post",
    data: data,
  })
}
export function addPriceMulti(data) {
  return axios.request({
    url: `${url}/logistics/price/multi`,
    method: "post",
    data: data,
  })
}
export function editPrice(id, data) {
  return axios.request({
    url: `${url}/logistics/price/${id}`,
    method: "put",
    data: data,
  })
}