<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>库存列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入名称" clearable></el-input>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="goAuth" style="margin-left:10px;margin-top:10px;">授权</el-button>
              <!-- <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">添加商品</el-button> -->
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="activeClick" style="margin-top:10px;">
          <el-tab-pane label="红酒库存" name="redWine"></el-tab-pane>
          <el-tab-pane label="包装库存" name="chest"></el-tab-pane>
        </el-tabs>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
          v-if="activeName==='redWine'"
        >
          <el-table-column label="商品" prop="goods_id" align="center" width="150">
            <template slot-scope="scope">
              <div>{{scope.row.goods.name}}</div>
            </template>
          </el-table-column>
          <el-table-column label="库存" prop="count" align="center" width="80"></el-table-column>
          <el-table-column label="进价" prop="purchase_price" align="center" width="80"></el-table-column>
          <el-table-column label="代理价" prop="agent_price" align="center" width="80"></el-table-column>
          <el-table-column label="零售价" prop="price" align="center" width="80"></el-table-column>
          <el-table-column label="是否上架" prop="status" align="center" width="120">
            <template slot-scope="scope">
              <el-tag size="small" v-if="(scope.row.status === 0)">未上架</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.status === 1)">已上架</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="仓库" prop="warehouse_id" align="center" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.logistics">{{scope.row.logistics.name}}</div>
              <div v-else>暂无数据</div>
            </template>
          </el-table-column>
          <el-table-column label="代理商" prop="agent_id" align="center" width="120">
            <template slot-scope="scope">
              <span>{{scope.row.agent.name }}</span>  
            </template>
          </el-table-column>
          <!-- <el-table-column label="购买单位" prop="unit">
            <template slot-scope="scope">
              <el-tag size="small" v-if="(scope.row.unit === 0)">1瓶装</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.unit === 1)">2瓶装</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.unit === 2)">3瓶装</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.unit === 3)">6瓶装</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column label="仓库类型" prop="type" align="center">
            <template slot-scope="scope">
              <el-tag size="small" v-if="(scope.row.logistics.type === 0)">本地仓库</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.logistics.type === 1)">云仓</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="包装类型" prop="chest_ids" width="120" align="center">
            <template slot-scope="scope">
              <el-tag v-for="(v,k) in scope.row.chests" effect="dark" size="mini" :hit="false">{{v.name}}</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="80" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-around;
                "
              >
                <el-link style="color: #409eff;margin-right:10px;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>

                <el-dropdown @command="command($event,scope.row)" style="margin-left:10px;">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="status" v-if="scope.row.status===0">上架</el-dropdown-item>
                    <el-dropdown-item command="status" v-if="scope.row.status===1">下架</el-dropdown-item>
                    <!-- <el-dropdown-item command="delete">删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        
        <el-table
          :data="tableData"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
          v-else
        >
          <el-table-column label="商品" prop="name" align="center" width="120">
          </el-table-column>
          <el-table-column label="库存" prop="count" align="center" width="120"></el-table-column>
          <el-table-column label="进价" prop="purchase_price" align="center" width="120"></el-table-column>
          <el-table-column label="零售价" prop="price" align="center" width="120"></el-table-column>
          <el-table-column label="仓库" prop="warehouse_id" align="center" width="120">
            <template slot-scope="scope">
              <div>{{scope.row.logistics.name}}</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="购买单位" prop="unit">
            <template slot-scope="scope">
              <el-tag size="small">{{scope.row.unit}}瓶装</el-tag>
            </template>
          </el-table-column> -->
          <el-table-column label="仓库类型" prop="type">
            <template slot-scope="scope">
              <el-tag size="small" v-if="(scope.row.type === 0)">本地仓库</el-tag>
              <el-tag size="small" type="success" v-if="(scope.row.type === 1)">云仓</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="包装类型" prop="type_name" width="120">
          </el-table-column>

          <el-table-column label="操作" width="80" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
              >
                <el-link style="color: #409eff;margin-right:20px;" @click="editPrice(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改包装价格" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>

                <!-- <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="delete">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-dialog
      width="30%"
      title="出库"
      align="center"
      :visible.sync="dialogVisible"
      append-to-body>
      <div class="icon-dialog">
        <el-input class="dialog-input" type="number" style="width:60%;margin-left:20%;" v-model="count" placeholder="请输入出库数量"></el-input>
        
      </div>
      <el-button @click="outConfirm" type="primary"  style="margin-left:10px;margin-top:30px;">确认</el-button>
      <el-button @click="outCancel" style="margin-left:50px;margin-top:10px;">取消</el-button>
         
    </el-dialog>
    <el-dialog
      width="30%"
      title="包装价格"
      align="center"
      :visible.sync="dialogChestVisible"
      append-to-body>
      <div class="icon-dialog" style="display:flex;justify-content: flex-start;align-items: center;">
        <div>包装价格：</div>
        <el-input class="dialog-input" type="number" style="width:60%;margin-left:10px;" v-model="chestPrice" placeholder="请输入包装价格"></el-input>
      </div>
      <el-button @click="chestPriceConfirm" type="primary"  style="margin-left:10px;margin-top:30px;">确认</el-button>
      <el-button @click="chestPriceCancel" style="margin-left:50px;margin-top:10px;">取消</el-button>
         
    </el-dialog>
    <el-dialog
      width="60%"
      title="授权"
      align="left"
      :visible.sync="dialogAuthVisible">
      <el-button type="primary" plain @click="addGoods">添加商品 +</el-button>
      <!-- <div @click="addGoods">
          添加商品 <span style="font-size:20px;margin-top:15px;">+</span>
      </div> -->
      <el-table :data="stocks" style="width: 100%;margin-bottom:20px;">
        <el-table-column prop="name" label="名称" width="240"></el-table-column>
        <el-table-column
            prop="unit"
            label="单位"
            align="center">
        </el-table-column>
        <!-- <el-table-column prop="count" label="数量">
            <template slot-scope="scope">
                <el-input v-model="scope.row.count" type="number" style="width:110px;"></el-input>
            </template>
        </el-table-column> -->
        <el-table-column prop="price" label="价格">
            <template slot-scope="scope">
                <el-input v-model="scope.row.price" type="number" style="width:110px;"></el-input>
            </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="text" size="small">删除</el-button>
            </template>
        </el-table-column>
      </el-table>
      <el-select v-model="agents" multiple clearable filterable placeholder="请选择代理商" style="width:40%;">
        <el-option
          v-for="(value,key) in agentList"
          :key="key"
          :label="value.name"
          :value="value.id">
        </el-option>
      </el-select>
      <div style="display:flex;justify-content: space-between;width:200px;margin:0 auto;">
        <el-button @click="authConfirm" type="primary"  style="margin-left:10px;margin-top:30px;">确认</el-button>
        <el-button @click="authCancel" style="margin-left:50px;margin-top:30px;">取消</el-button>
      </div>
      
         
    </el-dialog>
    <el-dialog
        width="60%"
        title="商品"
        :visible.sync="dialogGoodsVisible"
        append-to-body>
            <div class="icon-dialog">
                <div class="icon-dialog-list" v-for="(value,key) in goodsList" :key="key" @click="chooseGoods(value)">
                    <el-image 
                    style="width: 100px; height: 100px"
                    :src="value.images[0]" 
                    fit="cover">
                    </el-image>
                    <p class="icon-name">{{value.name}}</p>
                    <p class="icon-name">{{value.purchase_price}}</p>
                </div>
            </div>
        </el-dialog>
    <el-drawer
      title="更改库存信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="入库信息"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { getStocks,deleteStock,deleteChest,outStock,setStatus,getChestList,getStockListSelect,authorize,editChestPrice} from "@/api/stocks.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";
  import { getListSelect} from "@/api/agents.js";
  import {getList,getListSelect as getGoodsSelect} from "@/api/goods.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import storeList from "@/views/components/storeList.vue";

  let page_name = 'store_list'
  export default {
    name: "App",
    components:{addComp,editComp,storeList},
    data() {
      return {
        count:'',
        activeName:'redWine',
        admin_permission:[],
        dialogVisible:false,
        dialogGoodsVisible:false,
        dialogTableVisible:false,
        dialogAuthVisible:false,
        goodsList:[],
        stocks:[],
        agents:[],
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
        ],
        treeCurrentNode:"",
        storeTypeOptions:[],
        sizeOptions:[
          {id:0,label:"普通"},
          {id:1,label:"中型"},
          {id:2,label:"大型"},
          {id:3,label:"超大型"}
        ],
        layTypeOptions:[
          {id:0,label:"独家"},
          {id:1,label:"共存"},
        ],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        unit:'',
        unit_price:'',
        percentID:'',
        storeID:'',
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          username:'',
          gender:'',
          mobile:'',
          status:'',
          nickname:'',
          province_id:'',
          city_id:'',
          area_id:'',
          group_id:'',
          store_type:'',
          lay_type:'',
          store_size:'',
        },
        chestParams:{
          page: 1,
          page_count: 10
        },
          
        groupList:[],
        groupID:'',
        table:[],
        stocksList:[],
        agentList:'',
        tableData:[],
        service: [],
        editID: '',
        outID:'',
        total: 0,
        chestTotal:0,
        chestPriceID:"",//修改包装价格的id
        chestPrice:'',
        outerVisible: false,
        innerVisible: false,
        dialogChestVisible:false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getStocks(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      getChestList() {
        getChestList(this.chestParams).then(res=>{
            if(res.code === 200){
              this.tableData = res.data.data
              console.log(this.table)
              this.params.page = res.data.current_page
              this.params.page_count = res.data.per_page
              this.total = res.data.total
              this.$forceUpdate()
            }
          })
      },
      activeClick(tab, event) {
        if(this.activeName == 'redWine'){
          this.getTableData()
        }
        if(this.activeName == 'chest'){
          this.getChestList()
        }
      },
      editPrice(id){
        this.dialogChestVisible = true
        this.chestPriceID=id
      },
      
      chestPriceConfirm(){
        this.$confirm('确认修改价格吗？')
          .then(_ => {
            editChestPrice(this.chestPriceID, {price:parseInt(this.chestPrice)}).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success"
                });
                setTimeout(() => {
                  this.$emit("success");
                  this.dialogChestVisible=false
                }, 1000);
                this.getChestList()
              }
            });
          })
      },
      chestPriceCancel(){
        this.dialogChestVisible=false
      },
      handleDelete(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteStock(id).then(res=>{
                this.getTableData()
                this.$notify({
                  title: '成功',
                  message: '删除成功',
                  type: 'success'
                });
              })
          })
          .catch(_ => {});
      },
      handleDeleteChest(id) {
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteChest(id).then(res=>{
              this.getChestList()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
      },
      
      addGoods(){
          this.dialogGoodsVisible = true
      },
      chooseGoods(e){
          console.log(e)
          this.dialogGoodsVisible=false
          let params = {
              id:e.id,
              name:e.name,
              unit:'瓶',
              count:0,
              price:''
          }
          this.stocks.push(params)
      },
      
      handleClick(row){
          console.log(row)
          this.stocks = this.stocks.filter(item => item != row)
      },
      goAuth(){
        this.dialogAuthVisible = true
      },
      authConfirm(){
        console.log(this.stocks)
        this.$confirm('确认授权吗？')
          .then(_ => {
            authorize({stocks:this.stocks,agent_ids: this.agents}).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "授权成功",
                  type: "success"
                });
                setTimeout(() => {
                  this.$emit("success");
                  this.dialogAuthVisible=false
                }, 1000);
                this.getTableData()
              }
            });
          })
      },
      authCancel(){
        this.dialogAuthVisible=false
      },
      outStock(id) {
        this.dialogVisible=true
        this.outID=id
      },

      command(e,row){
        if(e === 'delete'){
          if(this.activeName == 'redWine'){
            console.log(1)
            this.handleDelete(row.id)
          }else{
            console.log(2)
            this.handleDeleteChest(row.id)
          }
        }
        if(e === 'output'){
          this.outStock(row.id)
        }
        if(e === 'status'){
          setStatus(row.id,{status:row.status ? 0 : 1}).then(res=>{
            if(res.code === 200){
              this.$notify({
                title: '成功',
                message: row.status ? '下架成功' : '上架成功',
                type: 'success'
              });
              this.getTableData();
            }
          })
        }
      },
      outConfirm(){
        this.$confirm('确认出库吗？')
          .then(_ => {
            outStock(this.outID, {count:this.count}).then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "出库成功",
                  type: "success"
                });
                setTimeout(() => {
                  this.$emit("success");
                  this.dialogVisible=false
                }, 1000);
                this.getTableData()
              }
            });
          })
      },
      outCancel(){
        this.dialogVisible=false
      },
      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        if(this.activeName == 'redWine'){
          this.params.page -= 1;
          this.getTableData()
        }
        if(this.activeName == 'chest'){
          this.chestParams.page -= 1;
          this.getChestList()
        }
      },
      nextpage() {
        if(this.activeName == 'redWine'){
          this.params.page += 1;
          this.getTableData()
        }
        if(this.activeName == 'chest'){
          this.chestParams.page += 1;
          this.getChestList()
        }
      },
      handleCurrentChange(val) {
        console.log(val)
        if(this.activeName == 'redWine'){
          this.params.page = val;
          this.getTableData()
        }
        if(this.activeName == 'chest'){
          this.chestParams.page = val;
          this.getChestList()
        }
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      // this.getChestList();
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
      getStockListSelect().then(res=>{
        if(res.code === 200){
          this.stocksList = res.data
        }
      })
      getGoodsSelect().then(res=>{
          if(res.code === 200){
              this.goodsList = res.data
              console.log(this.goodsList)
          }
      })
      getListSelect().then(res=>{
        if(res.code === 200){
          this.agentList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .icon-dialog{
    width: 100%;
    height:500px;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-y: scroll;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 10px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
        .icon-name{
            margin-top:10px;
            font-size: 12px;
        }
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
  
</style>
