<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">物流价格信息</el-divider>
      
      <el-form-item label="物流公司" prop="warehouse_id" required>
        <el-select v-model="form.warehouse_id" clearable filterable placeholder="请选择仓库" style="width:100%">
          <el-option
            v-for="(value,key) in warehouseOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="省份" prop="province_id" required>
        <el-select v-model="form.province_id" placeholder="请选择省份" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="城市" prop="city_id" required>
        <el-select v-model="form.city_id" placeholder="请选择城市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区" prop="area_id" required>
        <el-select v-model="form.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="物流公司" prop="logistic_id" required>
        <el-select v-model="form.logistic_id" clearable filterable placeholder="请选择物流公司" style="width:100%">
          <el-option
            v-for="(value,key) in companyOptions"
            :key="key"
            :label="value.name"
            :value="value.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最低消费" prop="free_amount" require>
        <el-input v-model="form.free_amount" placeholder="请输入最低消费"></el-input>
      </el-form-item>
      <el-form-item label="首重重量" prop="first_weight" require>
        <el-input v-model="form.first_weight" placeholder="请输入首重重量">
          <template slot="append">kg</template>
        </el-input>
          
      </el-form-item>
      <el-form-item label="首重价格" prop="first_weight_price" require>
        <el-input v-model="form.first_weight_price" placeholder="请输入首重价格"></el-input>
      </el-form-item>
      <el-form-item label="续重单位" prop="continuous_weight" require>
        <el-input v-model="form.continuous_weight" placeholder="请输入续重单位">
          <template slot="append">kg</template>
        </el-input>
      </el-form-item>
      <el-form-item label="续重价格" prop="continuous_price" require>
        <el-input v-model="form.continuous_price" placeholder="请输入续重价格"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="editData()" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  import {getPriceById,editPrice,getCompaniesListSelect} from "@/api/logistics.js";
  import {getWarehousesListSelect} from "@/api/stocks.js";
  import {getProvince,getCity,getArea} from "@/api/public.js";
  import UploadQiniu from "@/components/tool/lib/upload/index.vue";
  import DistrictsCascader from "@/components/tool/lib/districts-cascader/Index.vue";

  import Picture from '@/components/tool/lib/upload/index.vue';

  export default {
    props: {
      id: Number
    },
    components: {DistrictsCascader, UploadQiniu, Picture},
    data() {
      return {
        companyOptions:[],
        warehouseOptions:[],
        toolsConfig: ["districts"],
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id: "",
        city_id: "",
        area_id: "",
        form: {
          warehouse_id: "",
          province_id: "",
          city_id: "",
          area_id: "",
          logistic_id:'',
          first_weight:'',
          first_weight_price:'',
          continuous_weight:'',
          continuous_price:'',
        },
        rules: {
          "name": [
            {required: true, message: "请输入商户名称", trigger: "blur"},
          ],
          "province_id": [
            {required: true, message: "请选择省份", trigger: "change"},
          ],
          "city_id": [
            {required: true, message: "请选择城市", trigger: "change"},
          ],
          "area_id": [
            {required: true, message: "请选择区域", trigger: "change"},
          ],
          "warehouse_id": [
            {required: true, message: "请选择仓库", trigger: "change"},
          ],
          "logistic_id": [
            {required: true, message: "请选择物流公司", trigger: "change"},
          ],
          "first_weight": [
            {required: true, message: "请输入首重重量", trigger: "blur"},
          ],
          "first_weight_price": [
            {required: true, message: "请输入首重价格", trigger: "blur"},
          ],
          "continuous_weight": [
            {required: true, message: "请输入续重单位", trigger: "blur"},
          ],
          "continuous_price": [
            {required: true, message: "请输入续重价格", trigger: "blur"},
          ],
        },
      };
    },

    computed: {},

    mounted() {
      _this = this;
      
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        console.log(this.id)
        getPriceById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            // console.log(res.data)
            this.keywordResult = res.data.address
            console.log(this.form)
            getProvince().then((province)=>{
              if(province.code === 200){
                this.provinceList = province.data
                let province_id = ''
                this.provinceList.forEach((item,index)=>{
                  if(item.id===res.data.province_id){
                    province_id = item.province_id
                    getCity(province_id).then(city=>{
                      if(city.code === 200){
                        this.cityList = city.data
                        let city_id
                        this.cityList.forEach(item => {
                          if(item.id == res.data.city_id ){
                            city_id = item.city_id
                            getArea(city_id).then(res=>{
                              if(res.code === 200){
                                console.log(res.data)
                                this.areaList = res.data
                              }
                            })
                          }
                        });
                      }
                    })
                  }
                })
              }
            })
          }
        })
        getCompaniesListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.companyOptions = res.data
          }
        })
        getWarehousesListSelect(this.params).then(res=>{
          if(res.code === 200){
            this.warehouseOptions = res.data
          }
        })
      },
      provinceClick(){
        let that = this
        if(this.form.province_id === ''){
          this.cityList = [];
          this.areaList = [];
          this.form.city_id = ''
          this.form.area_id=''
          return false;
        }
        let province_id = ''
        this.provinceList.forEach(item => {
          if(item.id == that.form.province_id ){
            province_id = item.province_id
            this.form.city_id = ''
            this.form.area_id=''
            getCity(province_id).then(res=>{
              if(res.code === 200){
                this.cityList = res.data
              }
            })
          }
        });
      },
      cityClick(){
        let that = this
        if(this.form.city_id === ''){
          this.areaList = [];
          this.form.area_id=''
          return false;
        }
        let city_id
        this.cityList.forEach(item => {
          if(item.id == that.form.city_id ){
            city_id = item.city_id
            this.form.area_id=''
            getArea(city_id).then(res=>{
              if(res.code === 200){
                console.log(res.data)
                this.areaList = res.data
              }
            })
          }
        });
      },

      close() {
        _this.$emit("close");
      },

      editData() {
        editPrice(_this.id, _this.form).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            setTimeout(() => {
              _this.$emit("success");
            }, 1000);
          }
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
