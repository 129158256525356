<template>
    <div class="box">
        <div id="printWrap">
            <div style="text-align:center;padding:40px;border-bottom: 1px solid #000;">发货单</div>
            <div style="border:1px solid #000;">
                <div style="text-align:left;padding:10px;border-bottom: 1px solid #000;">发货明细</div>
                <el-table :data="sendList.list" width="100%" style="border:1px solid #ccc;">
                    <el-table-column
                        prop="name"
                        label="产品名称"
                        width="240"
                        align="left">
                    </el-table-column>
                    <el-table-column
                        prop="unit"
                        label="单位"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="quantity"
                        label="数量"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="weight"
                        label="重量（kg）"
                        align="center"
                        width="120">
                    </el-table-column>
                    <!-- <el-table-column
                        prop="chest"
                        label="包装名称"
                        width=""
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="chest_quantity"
                        label="包装数量"
                        width=""
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="chest_weight"
                        label="包装重量"
                        width=""
                        align="center">
                    </el-table-column> -->
                </el-table>
                <div style="width:100%;text-align: left;padding:10px;border-bottom: 1px solid #000;">发货主题：{{sendList.name}}</div>
                <div style="width:100%;display:flex;justify-content: flex-start;padding:10px;border-bottom: 1px solid #000;">
                    <!-- <div style="width:50%;display: flex;justify-content: flex-start;">发货方式：{{form.way}}</div> -->
                    <div style="width:30%;text-align: left;">运费：{{sendList.order.express_price}}（{{ sendList.logistics_company.name }}）</div>
                    <div style="width:40%;" v-if="sendList.order.express_type===1">运费付款方式：到付</div>
                    <div style="width:40%;" v-if="sendList.order.express_type===0">运费付款方式：现付</div>
                    <div style="text-align: left;" v-if="sendList.logistics_company.type===0">发货形式：快递</div>
                    <div style="text-align: left;" v-if="sendList.logistics_company.type===1">发货形式：物流</div>
                </div>
                <!-- <div style="width:100%;display:flex;justify-content: flex-start;padding:10px;border-bottom: 1px solid #000;">
                    <div>重量：{{form.express}}kg</div>
                </div> -->
                <div style="width:100%;display:flex;justify-content: flex-start;padding:10px;border-bottom: 1px solid #000;">
                    <div style="width:50%;text-align: left;">发货人：{{sendList.admin.nickname}}</div>
                    <div>所有者：{{sendList.agent}}</div>
                </div>
                <div style="width:100%;padding:10px;border-bottom: 1px solid #000;">
                    <div style="width:100%;display:flex;justify-content: flex-start;">
                        <div style="width:50%;text-align: left;">收货人：{{sendList.user_address.name}}</div>
                        <div>电话：{{sendList.user.phone}}</div>
                    </div>
                    <div style="text-align:left;margin-top:10px;">地址：{{sendList.province.full_name}}{{sendList.city.full_name}}{{sendList.area.full_name}}{{sendList.address}}</div>
                </div>
                <div style="width:100%;display:flex;justify-content: flex-start;border-bottom:1px solid #000;padding:10px;">备注：<span style="display:block;width:60%;height:100px;">{{sendList.comment}}</span></div>
                
            </div>
            
        </div>
        <!-- <div @click="addGoods">
            添加商品 <span style="font-size:20px;margin-top:15px;">+</span>
        </div> -->
        <el-button type="primary" style="margin-top:20px;" v-print="printObj">打印</el-button>
    </div>

  </template>
  
  <script>
    let _this;
    import {getStocksListSelect,getsendById} from "@/api/stocks.js";
    import {getList} from "@/api/goods.js";
    export default {
        props:['id'],
        data(){
            return{
                printObj: {
                    id: "printWrap",
                    popTitle: '葡萄酒供应链服务',
                    // extraCss: 'https://www.google.com,https://www.google.com',//公司链接
                    extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
                },
                // form:{
                //     status:0,
                //     name:'自动主题—葡萄酒干红',
                //     date:'2020-03-01',
                //     stock:'宁波仓库',
                //     user:'林钟权',
                //     way:'客户自提',
                //     serial:'KZ14564983156',
                //     date:'2022-09-25',
                //     express:'25',
                //     people:'林钟权',
                //     person:'林钟权',
                //     comment:"萨达咖啡机哦怕发送大附件哦怕的评价佛i阿飞骄傲平均得分啊萨达倒萨怕"
                // },
                // goodsList:[],
                // tableData:[
                //     {
                //         name:'红葡萄酒',
                //         net_weight:'150',
                //         unit:'瓶',
                //         num:'20',
                //         stock:'1150/5230',
                //     },
                //     {
                //         name:'白葡萄酒',
                //         net_weight:'64',
                //         unit:'瓶',
                //         num:'15',
                //         stock:'1150/5230',
                //     }
                // ],
                sendList:[]
            }
        },
        mounted(){
            console.log(this.id)
            this.init()
        },
        methods:{
            // 初始化数据
            init() {
                getList().then(res=>{
                    if(res.code === 200){
                        this.goodsList = res.data.data
                        console.log(this.goodsList)
                    }
                })
                getsendById(this.id).then(res=>{
                    if(res.code === 200){
                        this.sendList = res.data
                        console.log(this.sendList)
                    }
                })
            },
            // getSummaries(param) {
            //     const { columns, data } = param;
            //     const sums = [];
            //     columns.forEach((column, index) => {
            //     if (index === 0) {
            //         sums[index] = '合计';
            //         return;
            //     }
            //     const values = data.map(item => Number(item[column.property]));
            //     if (!values.every(value => isNaN(value))) {
            //         sums[index] = values.reduce((prev, curr) => {
            //         const value = Number(curr);
            //         if (!isNaN(value)) {
            //             return prev + curr;
            //         } else {
            //             return prev;
            //         }
            //         }, 0);
            //         sums[index] += '';
            //     } else {
            //         sums[index] = 'N/A';
            //     }
            //     });

            //     return sums;
            // }
        }
    };
  </script>
  <style lang="scss" scoped>
.box{
    font-size:16px;
   
    // padding:10px 20px;
}
  .icon-dialog{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-dialog-list{
      box-sizing: border-box;
      padding: 10px 10px;
      width: 16.66%;
      font-size: 32px;
      text-align: center;
      border:1px solid #dbdbdb;
        .icon-name{
            margin-top:10px;
            font-size: 12px;
        }
    }
    .icon-dialog-list:hover{
      color:#409eff;
      border-color:#409eff;
    }
  }
  </style>
  